import React, {  useEffect } from "react";

// import aboutUs from "../assets/img/aboutUs.png";
import aboutUs from "../assets/img/about-us.mp4";
import featured_Property from "../assets/img/featured_Property.svg";
import secured_Payment from "../assets/img/secured_Payment.svg";
import about_bottom_right from "../assets/img/about_bottom_right.webp";
import Topbar from "../components/Top Bar/Topbar";
import Footer from "../components/Footer/Footer";
import CustomNavbar from "../components/navBar/NavBar";
import { useTranslation } from "react-i18next";
import { NavLink  } from 'react-router-dom';


const AboutUs = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Topbar />
      <CustomNavbar />
      
      <div className="estate-header">
        <div className="header-txt text-white">
          <p>
              <NavLink to="/" className="nav-link d-inline">  {t('home')} </NavLink>  
              <i  className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {t('about_us')} 
          </p>
        
        </div>
      </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-md-6 mt-5">
            <div className="aboutUs-left-side">
            <video width="100%" height="90%"   autoPlay muted loop >
              <source src={aboutUs} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
              {/* <img src={aboutUs} alt="" /> */}
            </div>
            <div className="left-bottom">
              <h3>{t('scope')}</h3>
              <p>{t('about_left_para')}</p>
            </div>
          </div>
          
          {/* Right Side */}
          <div className="col-md-6 mt-5">
            <div className="aboutUs-right-side">
              <h6>{t('about_cap')}</h6>
              <h3>{t('platform')}</h3>
              <p>{t('about_right_para')}</p>
            </div>
            <div className="property mt-5">
              <div className="featured">
                <img src={featured_Property} alt="" />
                <h5>{t('feature')}</h5>
                <p>{t('care')}</p>
              </div>
              <div className="secured">
                <img src={secured_Payment} alt="" />
                <h5>{t('system')}</h5>
                <p>{t('safety')}</p>
              </div>
            </div>

            <div className="bottom-right">
                <img src={about_bottom_right} alt="" />
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </>
  );
};

export default AboutUs;