import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

import {
  Table,
  Dropdown,
  DropdownButton,
  InputGroup,
  Button,
  FormControl,
} from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ApiService from "../../../services/ApiService";
import { formatPrice } from "../../../utils/helper";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

const AuctionsList = () => {
  const { t } = useTranslation();
  const app_lang = localStorage.getItem("lang") || "en";
  const [properties, setProperties] = useState([]);
  const [pagination, setPagination] = useState({});
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [apiData, setApiData] = useState({
    search: "",
    daterange: "",
    page: 0,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getProperties();
  }, [apiData.page, apiData.search, apiData.daterange]);

  const getProperties = async () => {
    try {
      setIsLoader(true);
      const response = await ApiService.request({
        method: "GET",
        url: `my-auctions`, // Replace with your API endpoint
        params: apiData,
      });
      const data = response.data;
      if (data.status) {
        setProperties(data.data);
        setPagination(data.pagination);
      } else {
        toast.error(data.message);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
    }
  };
  const delData = async (id) => {
    const result = await Swal.fire({
      title: t("are_you_sure"),
      text: t("not_able_revert"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: t("yes"),
      cancelButtonColor: "#9E9B9B",
      cancelButtonText: t(t("no")),
    });

    if (result.isConfirmed) {
      const response = await ApiService.request({
        method: "DELETE",
        url: `properties/${id}`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        getProperties();
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    }
  };

  const updateStatus = async (id) => {
    const result = await Swal.fire({
      title: t("are_you_sure"),
      text: t("want_change_status"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4BC95F",
      confirmButtonText: t("yes"),
      cancelButtonColor: "#9E9B9B",
      cancelButtonText: t(t("no")),
    });

    if (result.isConfirmed) {
      const response = await ApiService.request({
        method: "POST",
        url: `properties/status/${id}`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        getProperties();
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    }
  };

  return (
    <div>
      <div className="real-estate-dashboard">
        <div className="d-flex justify-content-between align-items-center rounded estate-search-bar mt-5">
          <h3 className="p-3">{t("auctions")}</h3>
        </div>
      </div>
      <div className="table-estate p-3 mt-3 rounded bg-white">
        {isLoader && (
          <p className="text-center">
            <span class="spinner-border spinner-border-sm "></span> Loading
          </p>
        )}

        {properties?.length && (
          <Table responsive striped hover className="estate-table mt-3">
            <thead>
              <tr>
                <th>{t("thumbnail")}</th>
                <th>{t("title")}</th>
                <th>{t("asset_type")}</th>
                <th>{t("start_date")}</th>
                <th>{t("end_date")}</th>
                <th>{t("status")}</th>
              </tr>
            </thead>
            <tbody>
              {properties?.length > 0 ? (
                properties.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <img
                        src={item?.auction.media_files?.[0].file}
                        alt="Thumbnail"
                        width="75"
                      />
                    </td>
                    <td>{item.auction.title}</td>
                    <td>{item.auction.asset_type}</td>
                    <td>{moment(item.auction.start_date).format("L")}</td>
                    <td>{moment(item.auction.end_date).format("L")}</td>
                    <td>{item.pay_status}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" className="text-dark text-center mt-2">
                    {t("no_data")}!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
        {!properties?.length && (
          <div className="text-center mt-2">
            <button
              className="btn btn-base"
              onClick={() => navigate("/auctions")}
            >
              Explore Auctions
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuctionsList;
