import React, { useState } from "react";

const SocialMediaShare = () => {
  const [isOpen, setIsOpen] = useState(false);
  const currentPageUrl = window.location.href;

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentPageUrl)}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentPageUrl)}&text=Check%20this%20out!`,
    linkedin: `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(currentPageUrl)}`,
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      
        <i className="bi bi-share" onClick={toggleDropdown}></i> 

      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "40px",
            right: "0",
            backgroundColor: "white",
            border: "1px solid #ddd",
            borderRadius: "4px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          <a
            href={shareLinks.facebook}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "block",
              padding: "10px",
              textDecoration: "none",
              color: "#3b5998",
            }}
          >
             <i className="bi bi-facebook"></i> 

          </a>
          <a
            href={shareLinks.twitter}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "block",
              padding: "10px",
              textDecoration: "none",
              color: "#1da1f2",
            }}
          >
            <i className="bi bi-twitter"></i> 
          </a>
          <a
            href={shareLinks.linkedin}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "block",
              padding: "10px",
              textDecoration: "none",
              color: "#0077b5",
            }}
          >
            <i className="bi bi-linkedin"></i> 
          </a>
        </div>
      )}
    </div>
  );
};

export default SocialMediaShare;
