import React, { useEffect, useState, memo, useCallback } from 'react';
import { loadGoogleMapsScript } from '../services/GoogleMap'; // Adjust the path as necessary

const GoogleMapComponent = memo(({ locationNew, onLocationChanged }) => {
  const [mapInstance, setMapInstance] = useState(null);
  const [markerInstance, setMarkerInstance] = useState(null);
  const [location, setLocation] = useState(locationNew);

  useEffect(() => {
    // Update location when locationNew prop changes
    if (locationNew.lat !== 0) {
      setLocation(locationNew);
      // Update marker and map center if they are initialized
      if (mapInstance && markerInstance) {
        mapInstance.setCenter({ lat: locationNew.lat, lng: locationNew.lng });
        markerInstance.setPosition({ lat: locationNew.lat, lng: locationNew.lng });
      }
    }
  }, [locationNew, mapInstance, markerInstance]);

  useEffect(() => {
    const initializeMap = async () => {
      const map_key = process.env.REACT_APP_MAP_KEY;
  
      if (!map_key) {
        console.error("Google Maps API key is missing in the .env file");
        return;
      }
  
      try {
        const googleMaps = await loadGoogleMapsScript(map_key);
        const initialLocation = { lat: 24.7136, lng: 46.6753 };
  
        const map = new googleMaps.Map(document.getElementById('map'), {
          center: initialLocation,
          zoom: 15,
        });
  
        const marker = new googleMaps.Marker({
          position: initialLocation,
          map: map,
          draggable: true,
        });
  
        setMapInstance(map);
        setMarkerInstance(marker);
  
        // Autocomplete setup
        const autocomplete = new googleMaps.places.Autocomplete(
          document.getElementById('autocomplete')
        );
  
        // Update on place selection
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.geometry) {
            const newLocation = {
              text: place.formatted_address,
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };
            updateMapLocation(newLocation); // Update location and map
          }
        });
  
        // Update on marker drag
        marker.addListener('dragend', async (event) => {
          const geocoder = new googleMaps.Geocoder();
          const latLng = event.latLng;
  
          // Reverse geocoding to get the address based on the new marker position
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK' && results[0]) {
              const newLocation = {
                text: results[0].formatted_address,
                lat: latLng.lat(),
                lng: latLng.lng(),
              };
              updateMapLocation(newLocation); // Update location and map
            } else {
              console.error("Geocode failed due to: " + status);
            }
          });
        });
      } catch (error) {
        console.error("Error loading Google Maps script:", error);
      }
    };
  
    initializeMap();
  }, []);

  const updateMapLocation = useCallback(
    (newLocation) => {
      setLocation(newLocation);
      if (mapInstance) {
        mapInstance.setCenter({ lat: newLocation.lat, lng: newLocation.lng });
      }
      if (markerInstance) {
        markerInstance.setPosition({ lat: newLocation.lat, lng: newLocation.lng });
      }
      onLocationChanged(newLocation); // Emit to parent component
    },
    [mapInstance, markerInstance, onLocationChanged]
  );

  const handleInputChange = (e) => {
    const newText = e.target.value;
    setLocation((prev) => ({
      ...prev,
      text: newText,
    }));
    // onLocationChanged(location); // Emit to parent component
  };

  return (
    <div>
      <input
        type="text"
        value={location?.text || ''}
        onChange={handleInputChange}
        id="autocomplete"
        required
        className="form-control form-control-solid mb-5"
        placeholder="Enter a location"
      />
      <div id="map" style={{ height: '350px', width: '100%' }}></div>
      {/* Uncomment if you want to show location details */}
      {/* <div>
        <p>Location: {location.text}</p>
        <p>Latitude: {location.lat}</p>
        <p>Longitude: {location.lng}</p>
      </div> */}
    </div>
  );
});

export default GoogleMapComponent;
