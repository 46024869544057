import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../App.css";
import Navbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import "../components/header/header.css";
import Topbar from "../components/Top Bar/Topbar";
import "react-range-slider-input/dist/style.css";
import { Link, NavLink } from "react-router-dom";
import PageLoader from "../components/PageLoader";
import ApiService from "../services/ApiService";
import { toast } from "react-toastify";
import Paginator from "../components/Paginator";
import { CiCalendarDate } from "react-icons/ci";
import { RiErrorWarningLine } from "react-icons/ri";
import { PiCashRegister } from "react-icons/pi";
import { LuAlarmClock } from "react-icons/lu";
import { FaLocationDot } from "react-icons/fa6";
import { BsArchive } from "react-icons/bs";
import { IoMdAlarm } from "react-icons/io";
import { GoClock } from "react-icons/go";
import CountdownTimer2 from "../components/CountdownTimer/CountdownTimer2";
import pluralize from "pluralize";
import faviIcon from "../assets/img/favicon.png";
import {
  formatNumber,
  getConvertedTime,
  getDateMs,
  getDayName,
  getDaysDifference,
  isPastDate,
} from "../utils/helper";

const RealEstate = () => {
  const { t } = useTranslation();
  const [isPageLoader, setPageLoader] = useState(false);
  const [auctions, setAuctions] = useState([]);
  const [pagination, setPagination] = useState({});
  const app_lang = localStorage.getItem("lang") || "en";
  const [filterData, setFilterData] = useState({
    search: "",
    page: 1,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getAuctions();
  }, [filterData]);

  const getAuctions = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: "GET",
        url: `get-auction-groups`, // Replace with your API endpoint
        params: filterData,
      });
      const data = response.data;
      if (data.status) {
        setAuctions(data.data);
        setPagination(data.pagination);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };

  const handlePageChange = (event) => {
    setFilterData((prev) => ({ ...prev, page: event }));
  };

  return (
    <div className="">
      {isPageLoader ? (
        <PageLoader />
      ) : (
        <div>
          {/* Top Bar */}
          <Topbar />

          {/* Navbar */}
          <Navbar />

          {/* Header */}
          <div className="estate-header">
            <div className="header-txt text-white">
              <p>
                <NavLink to="/" className="nav-link d-inline">
                  {" "}
                  {t("home")}{" "}
                </NavLink>
                <i
                  className={`bi ${
                    app_lang === "en" ? "bi-chevron-right" : "bi-chevron-left"
                  }`}
                ></i>{" "}
                {t("auctions")}
              </p>
            </div>
          </div>
          <div className="background-color mb-5">
            <div className="px-5 py-4 bg-white">
              <h3 className="fw-normal">{t("auctions")}</h3>
              <div className="mb-3 d-flex gap-3">
                <div className="input-group w-auto">
                  <span className="input-group-text">
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="text"
                    id="search"
                    className="form-control"
                    placeholder={t("auction_search")}
                    // value={searchTerm}
                    // onChange={handleChange}
                  />
                </div>

                <select
                  name="type"
                  className="form-select w-auto"
                  placeholder="Auction Type"
                  // onChange={handleChange}
                  // value={filterData.type}
                >
                  <option value="Auction Type" selected>
                    {t("auction_type")}
                  </option>
                  <option value="infath">{t("infath")}</option>
                  <option value="private">{t("private")}</option>
                </select>

                <select
                  name="type"
                  className="form-select w-auto"
                  placeholder="Auction Type"
                  // onChange={handleChange}
                  // value={filterData.type}
                >
                  <option value="Auction Status" selected>
                    {t("auction_status")}
                  </option>
                  <option value="upcoming">{t("upcoming")}</option>
                  <option value="live">{t("live")}</option>
                  <option value="closed">{t("closed")}</option>
                </select>
              </div>
              <div className="row">
                {auctions?.map((item) => (
                  <AuctionGroupCard data={item} />
                ))}
              </div>

              {pagination.total_pages > 1 && (
                <Paginator
                  page={pagination.current_page}
                  rows={pagination.per_page}
                  totalRecords={pagination.total_records}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      )}
    </div>
  );
};

export default RealEstate;

const AuctionGroupCard = ({ data }) => {
  const { t } = useTranslation();

  const getStatus = (startDate, endDate) => {
    let status = null;
    const isPastStartDate = isPastDate(startDate);
    const isPastEndDate = isPastDate(endDate);

    if (!isPastStartDate && !isPastEndDate) status = "upcoming";
    if (isPastStartDate && !isPastEndDate) status = "live";
    if (isPastStartDate && isPastEndDate) status = "finished";

    return status;
  };

  const getAuctionLabel = (startDate, endDate) => {
    switch (getStatus(startDate, endDate)) {
      case "upcoming":
        return "upcoming";
      case "live":
        return "live";

      case "finished":
        return "closed";

      default:
        return "";
    }
  };

  const getAuctionStatus = (startDate, endDate) => {
    switch (getStatus(startDate, endDate)) {
      case "upcoming":
        return (
          <>
            <RiErrorWarningLine size={22} />
            <span>{t("auction_start")}</span>
          </>
        );
      case "live":
        return (
          <>
            <PiCashRegister size={22} />
            <span>
              {t("reg_is")} <b>{t("open")}</b>
            </span>
          </>
        );
      case "finished":
        return (
          <>
            <RiErrorWarningLine size={22} />
            <span>{t("auction_ended")}</span>
          </>
        );

      default:
        return "";
    }
  };

  const RoundedIcon = ({ icon }) => (
    <span className="py-1 px-2 align-items-center bg-light-alpha rounded-circle">
      {icon}
    </span>
  );

  return (
    <div className="col-lg-4 col-md-6 col-12 mb-3">
      <Link
        className="rounded-3 text-black text-decoration-none"
        to={`/auction-details/${data.id}`}
      >
        <div className="position-relative">
          <div className="position-absolute top-0 left-0 d-flex gap-2 p-2">
            <span className="d-flex gap-1 align-items-center py-1 px-2 rounded-5 bg-tertiary">
              <LuAlarmClock />
              {t(getAuctionLabel(data.start_date, data.end_date))}
            </span>
          </div>
          <img
            className="rounded-top-3 object-fit-cover"
            style={{ height: "15rem", width: "100%" }}
            alt="stock"
            src={data?.photos?.[0].file || faviIcon}
          />
        </div>
        <div className="border px-3 py-2">
          <div>
            <div className="mb-3">
              <h5 className="mb-1">{data.group_name}</h5>
              <div className="d-flex align-items-center gap-1">
                <span className="text-truncate">{data.location}</span>
                <FaLocationDot color="var(--bs-primary)" />
              </div>
            </div>
            {/* <img src="https://picsum.photos/50/30" alt="logo" /> */}

            <div className="row mb-3">
              <div className="col-6">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <RoundedIcon icon={<BsArchive />} />
                  <span>{pluralize(t("project"), data.auctions_count)}</span>
                  <b>{data.auctions_count}</b>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <RoundedIcon icon={<GoClock />} />
                  <div className="d-flex flex-column">
                    <span>
                      {getDayName(data.start_date)}, {t("it_starts")}
                    </span>
                    <span>{getConvertedTime(data.start_time)}</span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <RoundedIcon icon={<IoMdAlarm />} /> {pluralize(t("days"))}{" "}
                  <b>{getDaysDifference(data.start_date, data.end_date)}</b>{" "}
                  {t("duration")}
                </div>
                <div className="d-flex align-items-center gap-2">
                  <RoundedIcon icon={<CiCalendarDate />} />
                  <div className="d-flex flex-column">
                    <b>{data.start_date}</b>
                    <b>{data.end_date}</b>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center lh-sm">
              <div className="d-flex flex-column">
                <span className="mb-1 light-alpha">
                  {t("deposit_starts_From")}
                </span>
                <span>{formatNumber(+data.deposit_starts_from)} SAR</span>
              </div>
              <CountdownTimer2
                targetTimestamp={getDateMs(data.start_date)}
                startDate={data.start_date}
                endDate={data.end_date}
              />
            </div>
          </div>
        </div>
        <div className="bg-base text-white px-3 py-2 rounded-bottom-3">
          <div className="d-flex gap-2 justify-content-center align-items-center">
            {getAuctionStatus(data.start_date, data.end_date)}
          </div>
        </div>
      </Link>
    </div>
  );
};
