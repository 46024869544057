import React, {  useEffect, useState }  from "react";
import Topbar from "../components/Top Bar/Topbar";
import Footer from "../components/Footer/Footer";
import CustomNavbar from "../components/navBar/NavBar";
import ApiService from '../services/ApiService';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaPhoneAlt } from "react-icons/fa";
import '../assets/css/contactUs.css'
import { IoShareSocialOutline } from "react-icons/io5";
import { FaFacebook, FaTwitter, FaPinterest, FaWhatsapp, FaSnapchat, FaTiktok, FaTelegramPlane } from 'react-icons/fa';
const ContactUs = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';
  const [formData, setFormData] = useState({
    'name':'',
    'email':'',
    'phone':'',
    'message':'',
  });

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const contactUs = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.request({
        method: 'POST',
        url: `contactUs`, // Replace with your API endpoint
        data: formData
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        setFormData({
          'name':'',
          'email':'',
          'phone':'',
          'message':'',
        });
        
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
};



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Topbar />
      <CustomNavbar />

      <div className="estate-header">
        <div className="header-txt text-white">
          <p>
            <NavLink to="/" className="nav-link d-inline">  {t('home')} </NavLink>
            <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {t('contact')}
          </p>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-6 mt-5">
            <div className="card p-4 secondCard">
              <form onSubmit={contactUs} style={{ color: '#A7A7A7' }}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <label htmlFor="name">{t('name')} *</label>
                      <input type="text" className="form-control"  name="name"  
                                    onChange={handleChange} value={formData.name} />
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label htmlFor="phone">{t('phone')} *</label>
                      <input type="text" className="form-control"  name="phone"  
                                    onChange={handleChange} value={formData.phone} />
                    </div>
                    <div className="col-12 mb-3">
                      <label htmlFor="email">{t('email')}*</label>
                      <input type="email" className="form-control" name="email"  
                                    onChange={handleChange} value={formData.email}  />
                    </div>
                    <div className="col-12 mb-3">
                      <label htmlFor="message">{t('leave_message')}*</label>
                      <textarea  name="message"  
                          onChange={handleChange} value={formData.message}className="form-control" rows="5"></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn-color mt-3 text-white">{t('submit')}</button>
              </form>
            </div>
          </div>
          <div className="col-lg-6 mt-5 ">
            <h1>{t('contact')} </h1>
            <p style={{ color: '#A7A7A7' }}>{t('contact_form_desc')} </p>
            <div className="card col-lg-11 CardOne">
              <div className="d-flex flex-row justify-content-start">
                <div className="col-lg-2 p-3">
                  <p className="fontHover">
                    <FaMapMarkerAlt className="FaMapMarkerAlt" />
                  </p>
                </div>
                <div className="col-lg-10 p-3">
                  <p>{t('address')}</p>
                  <p className="text-dark">{t('saudi')} </p>
                </div>
              </div>
            </div>
            <div className="card col-lg-11 CardOne">
              <div className="d-flex flex-row justify-content-start">
                <div className="col-lg-2 p-3">
                  <p className="fontHover">
                    <FaPhoneAlt className="FaPhoneAlt " />
                  </p>
                </div>
                <div className="col-lg-10 p-3">
                  <p>{t('call_us')} </p>
                  <p className="text-dark">+966533492225</p>
                </div>
              </div>
            </div>
            <div className="card col-lg-11 CardOne">
              <div className="d-flex flex-row justify-content-start">
                <div className="col-lg-2 p-3">
                  <p className="fontHover">
                    <IoShareSocialOutline className="IoShareSocialOutline " />
                  </p>
                </div>
                <div className="col-lg-10 p-3">
                  <p>{t('follow')} </p>
                  <div className="d-flex flex-row fontIcons">
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="mx-2">
                      <FaFacebook size={18} />
                    </a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="mx-2">
                      <FaTwitter size={18} />
                    </a>
                    <a href="https://pinterest.com" target="_blank" rel="noopener noreferrer" className="mx-2">
                      <FaPinterest size={18} />
                    </a>
                    <a href="https://whatsapp.com" target="_blank" rel="noopener noreferrer" className="mx-2">
                      <FaWhatsapp size={18} />
                    </a>
                    <a href="https://snapchat.com" target="_blank" rel="noopener noreferrer" className="mx-2">
                      <FaSnapchat size={18} />
                    </a>
                    <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer" className="mx-2">
                      <FaTiktok size={18} />
                    </a>
                    <a href="https://telegram.org" target="_blank" rel="noopener noreferrer" className="mx-2">
                      <FaTelegramPlane size={18} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
