
// import React, {  useEffect } from "react";
// import Topbar from "../components/Top Bar/Topbar";
// import Footer from "../components/Footer/Footer";

// import { useTranslation } from "react-i18next";
// import { NavLink  } from 'react-router-dom';
// import favlogo from "../assets/img/favicon.png";
// import "../assets/css/AuctionGroupDetails.css";

import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../App.css";
// import Navbar from "../components/navBar/NavBar";
import CustomNavbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import "../components/header/header.css";
import Topbar from "../components/Top Bar/Topbar";
import "react-range-slider-input/dist/style.css";
import { Link, NavLink  } from 'react-router-dom';
import PageLoader from "../components/PageLoader";
import ApiService from '../services/ApiService';
import { toast } from "react-toastify";
import Paginator from "../components/Paginator"; 
import { FaHome } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { RiErrorWarningLine } from "react-icons/ri";
import { PiCashRegister } from "react-icons/pi";
import { LuAlarmClock } from "react-icons/lu";
import favlogo from "../assets/img/favicon.png";
import "../assets/css/AuctionGroupDetails.css";
import CountdownTimer from "../components/CountdownTimer/CountdownTimer";

const AuctionGroupDeatils = () => {
    const { t } = useTranslation();
    const [isPageLoader, setPageLoader] = useState(false);
    const [auctions, setAuctions] = useState([]);
    const [pagination, setPagination] = useState({});
    const app_lang=localStorage.getItem('lang') || 'en';
    const [filterData, setFilterData] = useState({
        "search": "",
        "page": 1
    });


    useEffect(() => {
        window.scrollTo(0, 0);
        getAuctions();
    }, [filterData]);

    const getAuctions = async () => {
        try {
        setPageLoader(true);
        const response = await ApiService.request({
            method: 'GET',
            url: `getAllAuctions`, // Replace with your API endpoint
            params:filterData
        });
        const data = response.data;
        if (data.status) {
            setAuctions(data.data);
            setPagination(data.pagination);
        } else {
            toast.error(data.message);
        }
        setPageLoader(false);
        } catch (error) {
        setPageLoader(false);
        }
    };

    const handlePageChange = (event) => {
        setFilterData(prev => ({ ...prev, page: event }));
    };


    // Get the current timestamp
        const now = new Date();

        // Add 15 days to the current date
        const futureDate = new Date(now);
        futureDate.setDate(now.getDate() + 15);

        // Get the timestamp for the future date
        const targetTimestamp = futureDate.getTime();

  return (
    <>
      <Topbar />
      <CustomNavbar />
      

      <div className="groupdetailsbox">
        <div className="groupdetailscontainer">
            <div className="groupdetailsrow1">
                
                <div class="styles_proprtyTitle">
                    <NavLink to={'/auctions'}>
                    <svg fill="#000000" height="30px" width="30px" version="1.1" id="Layer_1" 
                            viewBox="0 0 330 330" >
                        <path id="XMLID_6_" d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M205.606,234.394
                            c5.858,5.857,5.858,15.355,0,21.213C202.678,258.535,198.839,260,195,260s-7.678-1.464-10.606-4.394l-80-79.998
                            c-2.813-2.813-4.394-6.628-4.394-10.606c0-3.978,1.58-7.794,4.394-10.607l80-80.002c5.857-5.858,15.355-5.858,21.213,0
                            c5.858,5.857,5.858,15.355,0,21.213l-69.393,69.396L205.606,234.394z"/>
                        </svg>
                    </NavLink>
                    <h2>الروضة للمزادات</h2>
                </div>
                <p class="styles_subheading__Qdurf">Riyadh, AR Rawdah</p>

                <div class="position-relative">
                    <div class="position-absolute top-0 left-0 d-flex gap-2 p-2">
                        <span class="d-flex gap-1 align-items-center bg-white px-1 rounded-2"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="13" r="8"></circle><path d="M12 9v4l2 2"></path><path d="M5 3 2 6"></path><path d="m22 6-3-3"></path><path d="M6.38 18.7 4 21"></path><path d="M17.64 18.67 20 21"></path></svg>
                        <span>Upcoming</span></span><span class="bg-white px-1 rounded-2">Online</span>
                    </div>
                    <img class="rounded-3 groupimg" alt="stock" src="	https://cdn.pixabay.com/photo/2017/04/10/22/28/residence-2219972_1280.jpg" />
                </div>

                <div class="styles_actionContainer1">
                    <div class="styles_actionbtn false">
                        <button class="styles_brochureIcon__R5ksK"><span><i title="" class="iconwasalt icon-pdf-file false styles_color_purple__PaAty undefined styles_f_24__dtfRC"></i></span>Brochure</button>
                        <button class="styles_galleryIcon__RYqva styles_borderLeft__9Af4A"><span><i title="" class="iconwasalt icon-image false styles_color_purple__PaAty undefined styles_f_24__dtfRC"></i></span>Gallery</button>
                    </div>
                    <div class="styles_infathLogo">
                        <div class="styles_infathLogoDiv__nJcY6"><img alt="" src={favlogo} width="130" height="68" decoding="async" data-nimg="1" loading="lazy"  /></div>
                        <div class="styles_infathLogoDiv__nJcY6 undefined"><img alt="" src="https://infath.gov.sa/o/infath_theme/images/infath-color-logo.svg" width="130" height="68" decoding="async" data-nimg="1" loading="lazy"  /></div>
                    </div>
                </div>

            </div>
            <div className="groupdetailsrow2">
                <div className="timer1">
                <CountdownTimer targetTimestamp={targetTimestamp} />
                </div>
                <div className="salesdetailsbox">
                    <div>
                        <div class="manageby_managedBy__iOGIE  ">
                            <div class="manageby_tagOn__n4IPY">Sale Agent</div>
                                <h3 class="manageby_mngTitle__Jvjsr">شركة فهد ابراهيم المهوس للتطوير والاستثمار العقاري</h3>
                        </div>  
                    </div>    
                </div>
            </div>
            
        </div>
        <div class="groupProperties_property_heading__VJ2Xy"><h2> All Assets<span>(2)</span></h2></div>
        <div className="table-responsive">
              <table className="table  text-center mt-1">
              <thead className="bg-dark" >
                <tr >
                  <th scope="col">{t("img")}</th>
                  <th scope="col">{t("pro_summary")}</th>
                  <th scope="col">{t("curr_price")}</th>
                  <th scope="col">{t("bids_placed")}</th>
                  <th scope="col">{t("ending")}</th>
                  <th scope="col">{t("bids_now")}</th>
                </tr>
              </thead>
              <tbody>
              {auctions.map((auction) => (
                <tr>
                  <td><img src={auction.media_files[0].file} alt="Product" className="auction-img" /></td>
                  <td>{auction.title} </td>
                  <td>{t('currency')} {auction.open_price}</td>
                  <td>{auction.bids_count}</td>
                  <td>{auction.rem_days} {t("days")}</td>
                  <td>
                    <NavLink to={'/auction-details/'+auction.id} className="btn btn-base">{t("bid_now")}</NavLink>
                  </td>
                </tr>
              ))}
              </tbody>
              </table>
            </div>

            <div class="AuctionGroupDescriptionSection">
                <h2 class="AuctionGroupDescriptionSection_title">Auction Group Description</h2>
                <div class="AuctionGroupDescriptionSection_OnlyFonts"> شركة فهد ابراهيم المهوس للتطوير والاستثمار العقاري تعلن عن البيع بالمزاد العلني، بإشراف مركز التنفيذ والتصفية “التنفيذ” وبقرار من محكمة التنفيذ.</div>
            </div>
      </div>

   
    
      <Footer />
    </>
  );
};

export default AuctionGroupDeatils;