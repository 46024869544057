import { useEffect, useState } from "react";
import { FaRegClock } from "react-icons/fa";
import { isAuctionLive } from "../../utils/helper";

const CountdownTimer2 = ({
  className = "",
  targetTimestamp,
  disableClock = false,
  startDate,
  endDate,
  theme = "beta",
}) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetTimestamp));

  // Function to calculate the time left based on the target timestamp
  function calculateTimeLeft() {
    const now = new Date().getTime();
    const timeDifference = targetTimestamp - now;

    if (timeDifference <= 0) return null;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(targetTimestamp);
      setTimeLeft(newTimeLeft);
      if (!newTimeLeft) clearInterval(timer); // Stop the timer when the countdown is complete
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timer);
  }, [targetTimestamp]);

  if (isAuctionLive(startDate, endDate)) return null;

  if (theme === "alpha")
    return (
      <div className="countdown-container">
        <div className="countdown-headings">
          <span>Days</span>
          <span>Hours</span>
          <span>Mins</span>
          <span>Sec</span>
        </div>
        <div className="countdown-values">
          <span>{timeLeft.days}</span>
          <span>{timeLeft.hours}</span>
          <span>{timeLeft.minutes}</span>
          <span>{timeLeft.seconds}</span>
        </div>
      </div>
    );

  if (theme === "beta")
    return (
      <div className={className}>
        <div className="d-flex align-items-end gap-1">
          {disableClock && <FaRegClock className="pt-1" size={24} />}

          <div className="d-flex flex-column align-items-center lh-1 gap-1">
            <span className="fs-7 light-alpha">Days</span>
            <span className="fs-5">{timeLeft?.days}</span>
          </div>
          <span className="pt-3">:</span>
          <div className="d-flex flex-column align-items-center lh-1 gap-1">
            <span className="fs-7 light-alpha">Hrs</span>
            <span className="fs-5">{timeLeft?.hours}</span>
          </div>
          <span className="pt-3">:</span>
          <div className="d-flex flex-column align-items-center lh-1 gap-1">
            <span className="fs-7 light-alpha">Mins</span>
            <span className="fs-5">{timeLeft?.minutes}</span>
          </div>
          <span className="pt-3">:</span>
          <div className="d-flex flex-column align-items-center lh-1 gap-1">
            <span className="fs-7 light-alpha">Secs</span>
            <span className="fs-5">{timeLeft?.seconds}</span>
          </div>
        </div>
      </div>
    );
};

export default CountdownTimer2;
