import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';
import Home from '../pages/Home';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound'; 
import RealEstate from '../pages/RealEstate';
import PropertyDetails from '../pages/PropertyDetails';
import AboutUs from '../pages/AboutUs';
import TermsConditions from '../pages/TermsConditions';
import RightsPolicy from '../pages/RightsPolicy';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import RegistrationForm from '../pages/RegistrationForm';
import Packages from '../pages/Packages';
import BuyPackage from '../pages/BuyPackage';
import PaymentStatus from '../pages/PaymentStatus';

import Auctions from '../pages/Auctions';
import AuctionDetails from '../pages/AuctionDetails';
import RequestService from '../pages/RequestService';
import SearchByMap from '../pages/SearchByMap';

import NafathAuth from '../pages/NafathAuth';
import AdType from '../pages/AdType';
import AdvertismentValidator from '../pages/AdvertismentValidator';
import UserDashboard from '../pages/User/Dashboard';

import ContactUs from '../pages/ContactUs';
import CreateAd from '../pages/CreateAd';
import AuctionGroupDeatils from '../pages/AuctionGroupDetails';
import AssetDetails from '../pages/AssetDetails';



const AppRouter = () => {
    const isAuthenticated = !! JSON.parse(localStorage.getItem('user'))?.auth_token;
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<RegistrationForm />} />
                <Route path="/properties" element={<RealEstate />} />
                <Route path="/property/:slug" element={<PropertyDetails />} />
                <Route path="/packages" element={<Packages />} />
                <Route path="/auctions" element={<Auctions />} />
                <Route path="/auction-details/:id" element={<AuctionDetails />} />
                <Route path="/asset-details/:id" element={<AssetDetails />} />
                <Route path="/request-service" element={<RequestService />} />
                <Route path="/search-by-map" element={<SearchByMap />} />

                <Route path="/nafath-auth" element={  isAuthenticated ? <NafathAuth /> : <Navigate to="/login" />  } />
                <Route path="/ad-type" element={<AdType />} />
                <Route path="/advertisment-validator" element={<AdvertismentValidator />} />
                <Route path="/create-ad" element={<CreateAd />} />

                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/terms-and-conditions" element={<TermsConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/rights-policy" element={<RightsPolicy />} />
                <Route path="/contact-us" element={<ContactUs />} />

                <Route path="/purchase-package/:id" element={<BuyPackage />} />

                <Route path="/payment/:status" element={<PaymentStatus />} />
                <Route path="/auction_groupdetails" element={<AuctionGroupDeatils />}/>

                
                {/* // User Dashboard Routes  */}
                <Route
                    path="/dashboard"
                    element={    isAuthenticated ? <UserDashboard /> : <Navigate to="/login" />  }
                />
               
                
                <Route path="*" element={<NotFound />} /> {/* Keep this at the end*/}
            </Routes>
        </Router>
    );
};

export default AppRouter;
