import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "../App.css";
import Navbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import "../components/header/header.css";
import Topbar from "../components/Top Bar/Topbar";
import "react-range-slider-input/dist/style.css";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import Select from 'react-select';
import countryList from 'react-select-country-list';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PageLoader from "../components/PageLoader";
import ApiService from '../services/ApiService';

const RealEstate = () => {
  const { t } = useTranslation();
  const [isPageLoader, setPageLoader] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [categories, setCategories] = useState([]);

  const options = countryList().getData();
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };

  const handleCountryChange = (selectedOption) => {
    console.log("Selected country:", selectedOption.label);
  };
  const [formData, setFormData] = useState({
    category_id: "",
    full_name: "",
    phone: "",
    email: "",
    details: "",
    address: "",
    country: "Saudi Arabia",
    city: "",
    neighborhood: "",
    file: null,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategories();
  }, []);


  const getCategories = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: 'GET',
        url: `getServiceTypes`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setCategories(data.data);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        data.append(key, formData[key]);
      });

      const response = await ApiService.request({
        method: 'POST',
        url: `requestService`, // Replace with your API endpoint
        data,
      });

      if (response.data.status) {
        toast.success(response.data.message);
        setIsSubmitted(true);
        window.scrollTo(0, 0);
      } else {
        toast.error(response.data.message);
      }
      setIsLoader(false);
    } catch (error) {
      console.error('Form submission failed:', error);
    } finally {
      setIsLoader(false);
    }
  };

  return (
    <div>
      {isPageLoader ? (
        <PageLoader />
      ):(
        <div>
          {/* Top Bar */}
          <Topbar />

          {/* Navbar */}
          <Navbar />

          {/* Header */}
          <div className="estate-header">
            <div className="header-txt text-white">
              <p>
                {t("home")} <i class="bi bi-chevron-right"></i> {t("request")}
              </p>
            </div>
          </div>

          {/* Request a Service Form */}
          <div className="background-color mb-5">
            <div className="container form-main-head req-main-head mt-5 rounded p-4">
              <h2>{t("request")}</h2>

              {isSubmitted ? (
                <div className="alert alert-success mt-5">
                  <h4>Service Requested!</h4>
                  <p>Thank you for requesting service. we'll get back to you soon.</p>
                </div>

              ): (

                <div>
                  <Container className="mt-5">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="category_id">
                      <Form.Label className="form-label">
                      {t("serve_type")} <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control className="form-inp_s"
                        as="select"
                        name="category_id"
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">- {t("choose")} -</option>
                        {categories.map((category, index) => (
                          <option value={category.id} key={index}>{category.name}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="full_name" className="mt-3">
                      <Form.Label className="form-label">
                      {t("full_name")} <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control className="form-inp_s"
                        type="text"
                        name="full_name"
                        placeholder={t("write_full_name")}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>

                    <Form.Group controlId="phone" className="mt-3">
                      <Form.Label className="form-label">
                      {t("phone_num")} <span className="text-danger">*</span>
                      </Form.Label>
                      <PhoneInput
                      className="form-inp_s phone-inp_s ltr"
                        country={"sa"}
                        value={formData.phone}
                        onChange={handlePhoneChange}
                        inputProps={{
                          name: "phone",
                          required: true,
                          placeholder: "Enter your mobile number",
                        }}
                      />
                    </Form.Group>

                    <Form.Group controlId="email" className="mt-3">
                      <Form.Label className="form-label">
                      {t("email")}<span className="text-danger"> *</span>
                      </Form.Label>
                      <Form.Control className="form-inp_s"
                        type="email"
                        name="email"
                        placeholder={t("email")}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>

                    <Form.Group controlId="details" className="mt-3">
                      <Form.Label className="form-label">
                      {t("serv_details")} <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control className="form-inp_s"
                        as="textarea"
                        rows={3}
                        name="details"
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>

                    <div className="mt-3 row">
                      <div className="col-md-4">
                        <Form.Group controlId="address">
                          <Form.Label className="form-label">
                          {t("address")} <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control className="form-inp_s"
                            type="text"
                            name="address"
                            placeholder={t("detailed_address")}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <Form.Group controlId="country">
                          <Form.Label className="form-label">{t("country")}</Form.Label>
                          <Select
                            options={options}
                            styles={customStyles}
                            placeholder={t("select_country")}
                            onChange={handleCountryChange}
                            className="form-inp_s country_list"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <Form.Group controlId="city">
                          <Form.Label className="form-label">{t("city")}</Form.Label>
                          <Form.Control className="form-inp_s"
                            type="text"
                            name="city"
                            placeholder={t("choose_city")}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <Form.Group controlId="neighborhood" className="mt-3">
                      <Form.Label className="form-label">{t("neighborhood")}</Form.Label>
                      <Form.Control className="form-inp_s"
                        type="text"
                        name="neighborhood"
                        placeholder={t("neighborhood")}
                        onChange={handleInputChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="fileUpload" className="mt-3">
                      <Form.Label className="form-label">{t("upload_photo")}</Form.Label>
                      <Form.Control className="form-inp_s" type="file" onChange={handleFileChange} />
                    </Form.Group>

                    <Button type="submit" className="mt-4 req-btn rounded" disabled={isLoader}>
                      {t("send_req")} {isLoader && <span class="spinner-grow spinner-grow-sm"></span>}
                    </Button>
                  </Form>
                </Container>
            </div>


              )}

            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      )}
    </div>
  );
};

export default RealEstate;
