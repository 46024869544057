import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Topbar from "../components/Top Bar/Topbar";
import CustomNavbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import { NavLink } from 'react-router-dom';
import PageLoader from "../components/PageLoader";
import ApiService from '../services/ApiService';
import { toast } from "react-toastify";

const Packages = () => {
  const { t } = useTranslation();
  const [isPageLoader, setPageLoader] = useState(false);
  const [packages, setPackages] = useState([]);
  const app_lang = localStorage.getItem('lang') || 'en';

  // Retrieve user from localStorage
  const user = JSON.parse(localStorage.getItem('user'));

  // Check if the user is new based on created_at
  const isNewUser = (() => {
    if (user?.created_at) {
      const createdAt = new Date(user.created_at);
      const now = new Date();
      const differenceInDays = Math.floor((now - createdAt) / (1000 * 60 * 60 * 24));
      return differenceInDays <= 60; // Consider "new user" if created within the last 60 days
    }
    return false; // Default to false if no created_at is available
  })();

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllPackages();
  }, []);

  // Fetch all packages
  const getAllPackages = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: 'GET',
        url: `getAllPackages`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setPackages(data.data);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
      console.error("Error fetching packages", error);
    }
  };

  return (
    <div>
      {isPageLoader ? (
        <PageLoader />
      ) : (
        <div className="main-en">
          <Topbar />
          <CustomNavbar />
          {/* Header */}
          <div className="estate-header">
            <div className="header-txt text-white">
              <p>
                <NavLink to="/" className="nav-link d-inline"> {t('home')} </NavLink>
                <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {t('packages')}
              </p>
            </div>
          </div>

          {/* Package Body */}
          <div className="container">
            <div className="text-center my-5">
              <h1>{t('ad_packages')}</h1>
            </div>
            <div className="row justify-content-center">
              {packages.map((pkg) => {
                // Display package ID 11 only for new users
                if (pkg.id === 11 && !isNewUser) return null;

                return (
                  <div className="col-md-3" key={pkg.id}>
                    <div className="package1 text-center my-5">
                      <div className="package-head">
                        <h2>{pkg.name}</h2>
                        <h1>{t('currency')} {pkg.price} <sub>\ {t('ads')}</sub></h1>
                      </div>
                      <div className="package-body">
                        <p>{pkg.description}</p>
                        <div className="package-btn">
                          <NavLink to={`/purchase-package/${pkg.id}`}>{t('choose_package')}</NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Package Footer */}
          <div className="container-fluid package-footer">
            <div className="container d-flex justify-content-between align-items-center">
              <div>
                <h2>{t('package_footer_title')}</h2>
                <p className="mt-2 fw-lighter">{t('package_footer_desc')}</p>
              </div>
              <div className="package-footer-btn">
                <NavLink to="/request-service" className="nav-link">
                <button>{t('request')}</button>
                </NavLink>  
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </div>
  );
};

export default Packages;
