
import React, { useState, useEffect } from "react";
import Topbar from "../components/Top Bar/Topbar";
import Footer from "../components/Footer/Footer";
import CustomNavbar from "../components/navBar/NavBar";
import { useTranslation } from "react-i18next";
import { NavLink  } from 'react-router-dom';
import ApiService from '../services/ApiService';
import { toast } from "react-toastify";


const AdvertismentValidator = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';

  const [formData, setFormData] = useState({
    adLicenseNumber: '',
    advertiserId: '',
  });
  const [isLoader, setIsLoader] = useState(false);
  const [advertiser, setAdvertiser] = useState(null);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  const handleSubmit = async (e) => {
    setIsLoader(true);
    e.preventDefault();
    try {
      const response = await ApiService.request({
        method: 'POST',
        url: `advertismentValidator`, // Replace with your API endpoint
        data: formData
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        setAdvertiser(data.data.advertisement);
        localStorage.setItem('advertiser_info', JSON.stringify(data.data.advertisement));

        // navigate('/');
      } else {
        toast.error(data.message);
      }
      setIsLoader(false);
    } catch (error) {
      toast.error(error);
      setIsLoader(false);

    }
  };

  return (
    <>
      <Topbar />
      <CustomNavbar />
      
      <div className="estate-header">
        <div className="header-txt text-white">
          <p>
              <NavLink to="/" className="nav-link d-inline">  {t('home')} </NavLink>  
              <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {t('ad_validator')} 
          </p>
        
        </div>
      </div>

      <div className="container mb-5">
          <div className="bg-white p-5 mt-5 rounded" style={{ minHeight: '350px' }}>
            <h2 className="fw-normal">{t('ad_validator')} </h2>
            <h3 className="text-center mt-5">{t('check_advertising')} </h3>
            <div className="row mt-4">
              <div className="col-md-8 col-12 mx-auto">

                {!advertiser ? (
                  <form action="" method="post">
                    <label className="my-2">{t('ad_license_number')}</label>
                    <input type="number" name="adLicenseNumber"  onChange={handleChange} value={formData.adLicenseNumber}
                    className="form-control" placeholder={t('ad_license_number')} /> <br />

                    <label className="my-2">{t('advertiser_id')}</label>
                    <input type="number" name="advertiserId"  onChange={handleChange} value={formData.advertiserId}
                    className="form-control" placeholder={t('advertiser_id')} />    <br />

                    <button type="button" onClick={handleSubmit}  disabled={isLoader}  className="btn btn-base text-white">{t('check_ad')}
                      {isLoader && <span class="spinner-grow spinner-grow-sm text-white"></span>}
                    </button>
                  </form>
                ):(
                  <div>
                    <p className="alert alert-success h5">{t('ad_is_valid')}</p>
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>{t('advertiser_number')}</th>
                            <td>{advertiser?.advertiserId}</td>
                          </tr>
                          <tr>
                            <th>{t('license_number')}</th>
                            <td>{advertiser?.adLicenseNumber}</td>
                          </tr>
                          <tr>
                            <th>{t('ad_issue_date')}</th>
                            <td>{advertiser?.creationDate}</td>
                          </tr>
                          <tr>
                            <th>{t('instrument_number')}</th>
                            <td>{advertiser?.deedNumber}</td>
                          </tr>
                          <tr>
                            <th>{t('advertiser_name')}</th>
                            <td>{advertiser?.advertiserName}</td>
                          </tr>
                          <tr>
                            <th>{t('phone_number')}</th>
                            <td>{advertiser?.phoneNumber}</td>
                          </tr>
                          <tr>
                            <th>{t('marketing_brokerage_license_number')}</th>
                            <td>{advertiser?.brokerageAndMarketingLicenseNumber}</td>
                          </tr>
                          <tr>
                            <th>{t('restriction')}</th>
                            <td>{advertiser?.isConstrained}</td>
                          </tr>
                          <tr>
                            <th>{t('mortgaged')}</th>
                            <td>{advertiser?.isPawned}</td>
                          </tr>
                          <tr>
                            <th>{t('position')}</th>
                            <td>{advertiser?.isHalted}</td>
                          </tr>
                          <tr>
                            <th>{t('property_age')}</th>
                            <td>{advertiser?.propertyAge}</td>
                          </tr>
                          <tr>
                            <th>{t('street_view')}</th>
                            <td>{advertiser?.streetWidth}</td>
                          </tr>
                          <tr>
                            <th>{t('area')}</th>
                            <td>{advertiser?.propertyArea}</td>
                          </tr>
                          <tr>
                            <th>{t('property_price')}</th>
                            <td>{advertiser?.propertyPrice}</td>
                          </tr>
                          <tr>
                            <th>{t('property_type')}</th>
                            <td>{advertiser?.propertyType}</td>
                          </tr>
                          <tr>
                            <th>{t('no_rooms')}</th>
                            <td>{advertiser?.numberOfRooms}</td>
                          </tr>
                          <tr>
                            <th>{t('ad_type')}</th>
                            <td>{advertiser?.advertisementType}</td>
                          </tr>
                          <tr>
                            <th>{t('region')}</th>
                            <td>{advertiser?.location?.region}</td>
                          </tr>
                          <tr>
                            <th>{t('city')}</th>
                            <td>{advertiser?.location?.city}</td>
                          </tr>
                          <tr>
                            <th>{t('neighborhood')}</th>
                            <td>{advertiser?.location?.district}</td>
                          </tr>
                          <tr>
                            <th>{t('street')}</th>
                            <td>{advertiser?.location?.street}</td>
                          </tr>
                          <tr>
                            <th>{t('mail')}</th>
                            <td>{advertiser?.location?.postalCode}</td>
                          </tr>
                          <tr>
                            <th>{t('building_no')}</th>
                            <td>{advertiser?.location?.buildingNumber}</td>
                          </tr>
                          <tr>
                            <th>{t('coordinates')}</th>
                            <td>Lat: {advertiser?.location?.latitude}, Lng: {advertiser?.location?.longitude}</td>
                          </tr>
                          <tr>
                            <th>{t('property_facade')}</th>
                            <td>{advertiser?.propertyFace}</td>
                          </tr>
                          <tr>
                            <th>{t('property_usage')}</th>
                            <td>
                              {advertiser?.propertyUsages.map((usage) => (
                                <span className="badge bg-dark"> {usage}</span>
                              ))}
                              </td>
                          </tr>
                          <tr>
                            <th>{t('property_services')}</th>
                            <td>
                              {advertiser?.propertyUtilities.map((utl) => (
                                <span className="badge bg-dark"> {utl}</span>
                              ))}
                              </td>
                          </tr>
                          <tr>
                            <th>{t('plan_number')}</th>
                            <td>{advertiser?.planNumber}</td>
                          </tr>
                          <tr>
                            <th>{t('part_number')}</th>
                            <td>{advertiser?.landNumber}</td>
                          </tr>
                          <tr>
                            <th>{t('location_description')}</th>
                            <td>{advertiser?.locationDescriptionOnMOJDeed}</td>
                          </tr>

                          <tr>
                            <th>{t('guarantees_and_duration')}</th>
                            <td>{advertiser?.guaranteesAndTheirDuration}</td>
                          </tr>
                          <tr>
                            <th>{t('obligations')}</th>
                            <td>{advertiser?.obligationsOnTheProperty}</td>
                          </tr>
                          <tr>
                            <th>{t('sources')}</th>
                            <td>{advertiser?.adSource}</td>
                          </tr>
                          <tr>
                            <th>{t('license_link')}</th>
                            <td>{advertiser?.adLicenseUrl}</td>
                          </tr>
                        </thead>

                        
                      </table>
                    </div>

                    <NavLink to={'/dashboard?tab=add-property'} className="btn btn-base w-100 btn-lg">{t('the_next')}</NavLink>
                  </div>

                )}
                

              
               
              </div>

            </div>
          </div>
      </div>
      <Footer />
    </>
  );
};

export default AdvertismentValidator;