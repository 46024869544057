import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Topbar from "../components/Top Bar/Topbar";
import Footer from "../components/Footer/Footer";
import CustomNavbar from "../components/navBar/NavBar";
import "../assets/css/contactUs.css";
import { useTranslation } from "react-i18next";

import { useNavigate, NavLink } from 'react-router-dom';


const CreateAd = () => {
    const { t } = useTranslation();
    const app_lang = localStorage.getItem('lang') || 'en';
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [selectedTab, setSelectedTab] = useState("Company");
    const [formData, setFormData] = useState({
        company:{
            'name':'',
            'phone':'',
            'reg_no':'',
            'reg_date':'',
        },
        owner:{
            'name':'',
            'phone':'',
            'saudi_id':'',
            'dob':'',
        },
        multiowner:  {
            'name':'',
            'phone':'',
            'saudi_id':'',
            'dob':'',  
        }
    });

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        const keys = name.split('.');

        setFormData((prevFormData) => {
            const updatedData = { ...prevFormData };
            let current = updatedData;

            keys.forEach((key, index) => {
                if (index === keys.length - 1) {
                    current[key] = value;
                } else {
                    current = current[key];
                }
            });

            return updatedData;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        formData['owner_type']=selectedTab;
        localStorage.setItem('owner_info', JSON.stringify(formData));
        navigate('/dashboard?tab=add-property');
          
    };

    return (
        <div>
            <Topbar />
            <CustomNavbar />
            <div className="estate-header">
                <div className="header-txt text-white">
                    <p>
                        <NavLink to="/" className="nav-link d-inline">  {t('home')} </NavLink>
                        <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {t('create_ad')}
                    </p>
                </div>
            </div>
            <div className="container col-lg-10 offset-lg-1 mt-5">
                <div className="card p-3">
                    <h1 className="mb-4">{t('create_ad')}
                    </h1>
                    <div className="col-lg-8 offset-lg-2">
                        {/* Tabs Navigation */}
                        <ul className="nav nav-tabs mb-4">
                            <li className="nav-item col-lg-4">
                                <button
                                    className={`nav-link ad-navtab ${selectedTab === "Company" ? "active" : ""}`}
                                    onClick={() => handleTabClick("Company")}
                                >
                                    {t('company')}

                                </button>
                            </li>
                            <li className="nav-item col-lg-4">
                                <button
                                    className={`nav-link ad-navtab ${selectedTab === "Single Owner" ? "active" : ""}`}
                                    onClick={() => handleTabClick("Single Owner")}
                                >
                                    {t('single_owner')}

                                </button>
                            </li>
                            <li className="nav-item col-lg-4">
                                <button
                                    className={`nav-link ad-navtab ${selectedTab === "Multi Owner" ? "active" : ""}`}
                                    onClick={() => handleTabClick("Multi Owner")}
                                >
                                    {t('multi_owner')}

                                </button>
                            </li>
                        </ul>

                        {/* Forms */}
                        <form onSubmit={handleSubmit}>
                            {selectedTab === "Company" && (
                                <div className="">
                                
                                    <div className="mb-3">
                                        <label htmlFor="deedNumber" className="form-label textStyle">
                                            {t('deed_no')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control" id="deedNumber" name="company.deed_no"  
                                        onChange={handleChange} value={formData.company.deed_no} required />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label textStyle">
                                            {t('unified_no')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control"  name="company.unified_no"  
                                        onChange={handleChange} value={formData.company.unified_no}  required />
                                    </div>
                                    <div className="mb-3">
                                        <label  className="form-label textStyle">
                                            {t('agent_id_no')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control"   name="company.agent_id_no"  
                                        onChange={handleChange} value={formData.company.agent_id_no}  required />
                                    </div>
                                    <div className="mb-3">
                                        <label  className="form-label textStyle">
                                            {t('agency_no')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="number"  className="form-control" name="company.agency_no"  
                                        onChange={handleChange} value={formData.company.agency_no} required />
                                    </div>
                                    <div className="mb-3">
                                        <label  className="form-label textStyle">
                                            {t('agent_dob')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="date"  className="form-control" name="company.agent_dob"  
                                        onChange={handleChange} value={formData.company.agent_dob} required />
                                    </div>

                                    <div className="mb-3">
                                        <label  className="form-label textStyle">
                                            {t('agent_mobile')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="tel"  className="form-control" name="company.agent_mobile"  
                                        onChange={handleChange} value={formData.company.agent_mobile} required />
                                    </div>
                                </div>
                            )}
                            {selectedTab === "Single Owner" && (
                                <div>
                                    <div className="mb-3">
                                        <label htmlFor="deedNumber" className="form-label textStyle">
                                            {t('deed_no')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control" id="deedNumber" name="owner.deed_no"  
                                        onChange={handleChange} value={formData.owner.deed_no}  required />
                                    </div>
                                    <div className="mb-3">
                                        <label  className="form-label textStyle">
                                            {t('owner_mobile')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="tel" className="form-control"  name="owner.owner_mobile"  
                                        onChange={handleChange} value={formData.owner.owner_mobile}  required />
                                    </div>
                                    <div className="mb-3">
                                        <label  className="form-label textStyle">
                                            {t('owner_id_no')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="tel" className="form-control" name="owner.owner_id_no"  
                                        onChange={handleChange} value={formData.owner.owner_id_no}  required />
                                    </div>
                                    <div className="mb-3">
                                        <label  className="form-label textStyle">
                                            {t('owner_dob')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="date" className="form-control" name="owner.owner_dob"  
                                        onChange={handleChange} value={formData.owner.owner_dob} required />
                                    </div>

                                </div>
                            )}

                            {selectedTab === "Multi Owner" && (
                                <div>
                                    <div className="mb-3">
                                        <label htmlFor="deedNumber" className="form-label textStyle">
                                            {t('deed_no')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control" id="deedNumber" name="multiowner.deed_no"  
                                        onChange={handleChange} value={formData.multiowner.deed_no}  required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="ownerNames" className="form-label textStyle">
                                            {t('id_one_owner')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control" name="multiowner.id_one_owner"  
                                        onChange={handleChange} value={formData.multiowner.id_one_owner} required />
                                    </div>
                                
                                    <div className="mb-3">
                                        <label  className="form-label textStyle">
                                            {t('agent_id_no')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control"   name="multiowner.agent_id_no"  
                                        onChange={handleChange} value={formData.multiowner.agent_id_no}  required />
                                    </div>
                                    <div className="mb-3">
                                        <label  className="form-label textStyle">
                                            {t('agent_dob')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="date"  className="form-control" name="multiowner.agent_dob"  
                                        onChange={handleChange} value={formData.multiowner.agent_dob} required />
                                    </div>

                                    <div className="mb-3">
                                        <label  className="form-label textStyle">
                                            {t('agent_mobile')}<span className="text-danger">*</span>
                                        </label>
                                        <input type="tel"  className="form-control" name="multiowner.agent_mobile"  
                                        onChange={handleChange} value={formData.multiowner.agent_mobile} required />
                                    </div>

                                </div>
                            )}

                        <button type="submit"  className="btn btn-base text-white w-100 mt-2">
                            {t('save_and_continue')}
                        </button>
                        <div className="text-center mt-2">
                            <a href="" className="text-base nav-link">{t('contact_us_if_you_need_support')}</a>
                        </div>
                       </form>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <Footer />
        </div>
    );
};

export default CreateAd;
