import React, { useState, useEffect, useMemo } from "react";
import CustomNavbar from "../components/navBar/NavBar";
import Footer from "../components/Footer/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Topbar from "../components/Top Bar/Topbar";
import PageLoader from "../components/PageLoader";
import ApiService from "../services/ApiService";
import { toast } from "react-toastify";
import { FaRegFilePdf } from "react-icons/fa6";
import { GrGallery } from "react-icons/gr";
import {
  Carousel,
  Container,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  InputGroup,
  FormControl,
  Modal,
} from "react-bootstrap";
import CountdownTimer2 from "../components/CountdownTimer/CountdownTimer2";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import Carousel2 from "../components/Carousel/Carousel2";
import BackButton from "../components/BackButton";
import { getDateMs, isAuctionLive, isPastDate } from "../utils/helper";
import logoInfath from "../assets/img/logo-infath.jpeg";

const AuctionDetails = () => {
  const { t } = useTranslation();
  const app_lang = localStorage.getItem("lang") || "en";

  const [show, setShow] = useState(false);
  const [mediaType, setMediaType] = useState("videos");

  const [isPageLoader, setPageLoader] = useState(false);
  const [auction, setAuction] = useState({});
  const [assets, setAssets] = useState({});
  const [bids, setBids] = useState([]);
  const [alreadyBid, setAlreadyBid] = useState(false);
  const [amount, setAmount] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };
  const { id } = useParams();

  const auctionMedia = useMemo(() => {
    return auction?.[mediaType] || [];
  }, [mediaType, auction]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAuctionDetails();
  }, []);

  const getAuctionDetails = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: "GET",
        url: `get-auction-assets/${id}`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setAuction(data.auction);
        setAssets(data);
        // setBids(data.data.bids);
        // setAlreadyBid(data.data.alreadyBid);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.request({
        method: "POST",
        url: `bidAuction`, // Replace with your API endpoint
        data: { auction_id: id, amount: amount },
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        setAmount("");
        getAuctionDetails();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const targetTimestamp = new Date(auction.end_date).getTime();

  const TABLE_DATA = [
    {
      ownership: "Pehtoer",
      location: "Delhi, India",
      the_space: "200",
      deposit: "520",
      enter_the_bid: "1000",
      time: "32",
      an_act: "sd32r32f",
    },
  ];

  const ITEMS = [
    { src: "https://picsum.photos/id/237/200/300" },
    { src: "https://picsum.photos/id/238/200/300" },
  ];

  return (
    <div className="">
      {isPageLoader ? (
        <PageLoader />
      ) : (
        <div>
          <GalleryModal show={show} setShow={setShow} />
          <div>
            <Topbar />
            <CustomNavbar />

            <div className="bg-white py-4 px-5">
              <Row className="w-100">
                <h3>
                  <BackButton /> {auction.group_name}
                </h3>
                <Col md={7}>
                  <Carousel2 items={auctionMedia} disableThumbnails />

                  <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex gap-3">
                      <button
                        className="btn btn-base py-2 px-4"
                        onClick={() => window.open(auction.brochure, "_blank")}
                      >
                        {t("brochure")} <FaRegFilePdf />
                      </button>

                      <button
                        className="btn btn-base py-2 px-4"
                        // onClick={() => setShow(true)}
                        onClick={() => {
                          setMediaType((ps) =>
                            ps === "photos" ? "videos" : "photos"
                          );
                        }}
                      >
                        {mediaType === "photos" ? t("videos") : t("gallery")}{" "}
                        <GrGallery />
                      </button>
                    </div>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="d-flex flex-column align-items-center">
                    <div className="w-100 text-center fw-bold">
                      <AuctionStatus data={auction} theme={"alpha"} />
                    </div>
                    <div className="border rounded-3 p-4 mt-3">
                      <div className="d-flex mb-3">
                        <span className="border px-1 rounded-2">
                          <b>{t("selling_agent")}</b>- {auction.sales_agent}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between gap-3">
                        <Button
                          className="d-flex justify-content-center align-items-center gap-2 w-100 bg-light-beta"
                          variant="light"
                        >
                          <span
                            className="text-base"
                            title={auction.sales_agent_number}
                          >
                            {t("connection")}
                          </span>
                          <FaPhoneAlt className="text-base" />
                        </Button>
                        <Button
                          className="d-flex justify-content-center align-items-center gap-2 w-100 bg-light-beta"
                          variant="light"
                          style={{ color: "#068848" }}
                        >
                          <span style={{ color: "#068848" }}>
                            {t("whatsapp")}
                          </span>
                          <FaWhatsapp color="#068848" />
                        </Button>
                      </div>
                    </div>
                    <div className="d-flex gap-4 mt-3">
                      {auction?.auction_type === "infath" && (
                        <>
                          <img
                            src={logoInfath}
                            alt="Product"
                            className="auction-img"
                          />
                          <div class="vr"></div>
                        </>
                      )}
                      <img
                        src={auction?.group_icon}
                        alt="Product"
                        className="auction-img"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="table-responsive mt-5">
                <div className="mb-2">
                  {t("all_assets")} ({assets?.data?.length})
                </div>
                <table className="table text-center table-bordered">
                  <thead className="bg-tertiary">
                    <tr className="bg-tertiary">
                      <th className="bg-tertiary text-start" scope="col">
                        Logo
                      </th>
                      <th className="bg-tertiary text-start" scope="col">
                        {t("title")}
                      </th>
                      <th className="bg-tertiary text-start" scope="col">
                        {t("asset_type")}
                      </th>
                      <th className="bg-tertiary text-start" scope="col">
                        {t("location")}
                      </th>
                      <th className="bg-tertiary text-start" scope="col">
                        {t("deposit")}
                      </th>
                      <th className="bg-tertiary text-start" scope="col">
                        {t("time")}
                      </th>
                      <th className="bg-tertiary text-start" scope="col">
                        {t("Actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {assets.data?.map((asset) => (
                      <tr>
                        <td className="align-middle text-start">
                          <img
                            src={asset?.media_files?.[0]?.file}
                            alt="Product"
                            className="auction-img"
                          />
                        </td>
                        <td className="align-middle text-start">
                          {asset.title}
                        </td>
                        <td className="align-middle text-start">
                          {asset.asset_type}
                        </td>
                        <td className="align-middle text-start text-truncate">
                          {asset.location}
                        </td>
                        <td className="align-middle text-start">
                          {asset.deposit_starts_from}
                        </td>
                        <td className="align-middle text-start">
                          <AuctionStatus data={asset} theme={"beta"} />
                        </td>
                        <td className="align-middle text-start">
                          <button
                            className="btn btn-base"
                            onClick={() => {
                              navigate(`/asset-details/${asset.id}`);
                            }}
                          >
                            {t("registration")}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-4">
                <h3>{t("auction_lot_desc")}</h3>
                <div
                  className="border-bottom pb-3"
                  dangerouslySetInnerHTML={{ __html: auction.description }}
                ></div>
                <div className="fs-5 mt-3">
                  {t("added")}: {moment(auction.created_at).fromNow()} |{" "}
                  {t("group_reference_number")}: 543
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );

  function GalleryModal({ show, setShow }) {
    return (
      <Modal show={show} fullscreen onHide={() => setShow(false)}>
        <Modal.Header closeButton></Modal.Header>
        <div className="bg-black p-4">
          <Carousel2 items={ITEMS} imageStyles={{ height: "28rem" }} />
        </div>
      </Modal>
    );
  }

  function AuctionStatus({ data, theme }) {
    return (
      <>
        {isAuctionLive(data.start_date, data.end_date) ? (
          t("auction_live")
        ) : isPastDate(data.end_date) ? (
          t("auction_ended")
        ) : (
          <CountdownTimer2
            targetTimestamp={getDateMs(data.start_date)}
            startDate={data.start_date}
            endDate={data.end_date}
            theme={theme}
          />
        )}
      </>
    );
  }
};

export default AuctionDetails;
