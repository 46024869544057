import moment from "moment";

export function formatPrice(number) {
  return new Intl.NumberFormat("en-US").format(number);
}

export const getDateMs = (value) => {
  const date = new Date(value); // Create a new Date object for the specified date
  const milliseconds = date.getTime(); // Get the time in milliseconds since the Unix Epoch

  return milliseconds;
};

export const getDaysDifference = (startDate, endDate) => {
  // Define the two dates
  startDate = moment(startDate);
  endDate = moment(endDate);

  // Calculate the difference in days
  const differenceInDays = endDate.diff(startDate, "days");

  return differenceInDays || "";
};

export const getConvertedTime = (time) => {
  //convert time 24hrs format to 12hrs format, 18:31:00 --> 06:31 PM

  // Parse the time and format it to 12-hour format with AM/PM
  const formattedTime = moment(time, "HH:mm:ss").format("hh:mm A");
  return formattedTime;
};

export const getDayName = (date) => {
  return moment(date).format("dddd");
};

export const isPastDate = (date) => {
  // Define the date to check
  const dateToCheck = moment(date);

  // Get the current date
  const currentDate = moment();

  // Check if the date is in the past
  const isPast = dateToCheck.isBefore(currentDate);
  return isPast;
};

/**
 * Formats a number with commas as thousand separators and optional decimal places.
 * @param {number} amount - The number to format.
 * @param {number} [decimalPlaces=2] - The number of decimal places to include.
 * @returns {string} The formatted number as a string.
 */
export function formatNumber(amount = 0, decimalPlaces = 0) {
  if (!amount) return 0;
  amount = +amount;
  // Ensure the input is a number
  if (isNaN(amount)) {
    throw new Error("Invalid number");
  }

  // Format the number with fixed decimal places
  const formattedAmount = amount.toFixed(decimalPlaces);

  // Add commas as thousand separators
  return formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getRedirectionLink = () => {
  let link = sessionStorage.getItem("redirect_link");
  return link;
};

export const setRedirectionLink = (data) => {
  sessionStorage.setItem("redirect_link", data);
};

export const isAuctionLive = (startDate, endDate) => {
  if (isPastDate(startDate) && !isPastDate(endDate)) return true;
  return false;
};

export function isCurrentTimeInRange(startTime, endTime, startDate, endDate) {
  // Get the current date and time
  const now = new Date();

  // Parse the start and end dates and times
  const startDateTime = new Date(`${startDate}T${startTime}`);
  const endDateTime = new Date(`${endDate}T${endTime}`);

  // Check if the current time is within the range
  return now >= startDateTime && now <= endDateTime;
}

export function calculatePercentage(percentage, total) {
  return (percentage / 100) * total;
}
