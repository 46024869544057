import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "../components/FilterSideBar/filterSideBar.css";
import CustomNavbar from "../components/navBar/NavBar";
import Topbar from "../components/Top Bar/Topbar";
import Footer from "../components/Footer/Footer";
import PageLoader from "../components/PageLoader";
import MapWithMarkers from "../components/MapWithMarkers";
import { toast } from "react-toastify";
import ApiService from '../services/ApiService';
import PropertyCard from "../components/PropertyCard";



const FilteredSideBar = () => {
  const { t } = useTranslation();
  
  const [isPageLoader, setPageLoader] = useState(false);
  const [isClear, setClear] = useState(0);
  const [categories, setCategories] = useState([]);
  const [features, setFeatures] = useState([]);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [properties, setProperties] = useState([]);
  const [pagination, setPagination] = useState({});

  const toggleCheckboxes = () => {
    setShowCheckboxes((prev) => !prev);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const bottomOffset = document.documentElement.scrollHeight - scrollPosition;
    const isMobile = window.innerWidth <= 876;

    if (bottomOffset < 700) {
        setIsSticky(false); 
    } else if (!isMobile && window.scrollY > 100) { //100px
        setIsSticky(true);
    } else {
        setIsSticky(false);
    }
};
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const defaultFilterData = {
    search: "",
    type: "",
    category_id: "",
    features: [],
    bedrooms: [0, 100],
    bathrooms: [0, 100],
    area: [0, 1000],
    price: [10, 500000000],
    sort_by: "",
  };
  const [filterData, setFilterData] = useState(defaultFilterData);


  
  useEffect(() => {
    window.scrollTo(0, 0);
    getProperties();
  }, [filterData.sort_by, isClear]);

  const getProperties = async () => {
    try {
      setPageLoader(true);
      const response = await ApiService.request({
        method: 'POST',
        url: `filterProperties`, // Replace with your API endpoint
        data:filterData
      });
      const data = response.data;
      if (data.status) {
        setProperties(data.data);
        setPagination(data.pagination);
      } else {
        toast.error(data.message);
      }
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };
    
  useEffect(() => {
    
    getDropdownData();
  }, []);

  const getDropdownData = async () => {
    try {
      const response = await ApiService.request({
        method: 'GET',
        url: `getDropdownData?type=listing`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setCategories(data.data.categories);
        setFeatures(data.data.features);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleRangeChange = (name) => (value) => {
    setFilterData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
    }));
  };
  const handleFeatureChange = (e) => {
    const { value } = e.target;
    setFilterData((prevFormData) => {
      const features = prevFormData.features.includes(value)
        ? prevFormData.features.filter((feature) => feature !== value) // remove if already exists
        : [...prevFormData.features, value]; // add if not exists
      return { ...prevFormData, features };
    });
  };
 
  
  const resetFilter =  () => {
    setFilterData(defaultFilterData); // Reset to default state
    setClear(isClear+1);
  };

  return (
    <div className="main-property">
      {isPageLoader ? (
        <PageLoader />
      ):(
        <div>
          <Topbar />
          <CustomNavbar />
          <div className="estate-header">
            <div className="header-txt text-white">
              <p>
                {t("home")} <i class="bi bi-chevron-right"></i> {t("search_map")}
              </p>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 col-sm-12 order-2">
                <div className="sidebar shadow-sm py-3 px-4  bg-white rounded w-100">
                  <div className="sidebar-search">
                    <input
                      type="text"
                      value={filterData.search}
                      placeholder={t('looking_for')}
                      className="mt-3 w-100"
                      onChange={handleChange}
                      name="search"
                    />
                  </div>
                  <div className="row">
                    <div className="categories-options mb-3 col-md-6">
                      <select name="type" className="mt-3 w-100"   onChange={handleChange}  value={filterData.type}>
                        <option value="Property Type" selected>
                        {t("type")}
                        </option>
                        <option value="Sell">{t("sell")}</option>
                        <option value="Rent">{t("rent")}</option>
                      </select>
                    </div>
                    <div className="categories-options mb-3 col-md-6">
                      <select name="category_id" className="mt-3 w-100" onChange={handleChange} value={filterData.category_id}>
                        <option value="All Categories" selected>
                        {t("categories")}
                        </option>
                        {categories.map((category, index) => (
                          <option value={category.id} key={index}>{category.name}</option>
                        ))}
                        
                      </select>
                    </div>
                    <div className="col-md-6">
                        <div className="filterBox">
                            <h6>{t("filter_search")}</h6>
                            <RangeSlider  
                              onInput={handleRangeChange('price')}
                              value={filterData.price}
                              min={49}
                              max={500000000}
                              step={1}
                            />
                            <div className="d-flex pt-2 pb-2 priceRange">
                              <span className="mx-2">
                              {t("from")}:
                                <strong className="text-success">{filterData.price[0]}</strong>
                              </span>
                              <div className="ml-auto">
                              {t("to")}:
                                <strong className="text-success">{filterData.price[1]}</strong>
                              </div>
                            </div>
                          </div>
                    </div>

                    <div className="col-md-6 d-flex align-items-center">
                      <div
                        className="filter me-3"
                        onClick={toggleCheckboxes}  >
                        <i className="bi bi-filter-circle"></i>
                      </div>
                          <button className="btn btn-base btn-sm  w-50 text-white mx-2  py-2" onClick={getProperties}>
                            {t("search_cat")} <i className="bi bi-search"></i>
                          </button>
                        <button className="btn btn-outline-dark  w-75" onClick={resetFilter}>
                          <i class="bi bi-arrow-repeat"></i> {t("reset")}
                        </button>
                    </div>


                  </div>

                  {/* //Features Checkboxes  and Range Sliders */}
                  {showCheckboxes && (
                    <div>
                        <div className="row mt-3">
                          {features.map((feature, index) => (
                            <div className="col-6 col-md-4 mb-2" key={index}>
                              <label className="">
                                <input type="checkbox" className="form-check-input large-checkbox" value={feature.name} name='features' 
                                  checked={filterData.features.includes(feature.name)}  onChange={handleFeatureChange}/>
                                  {feature.name} 
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="row">
                            <div className="col-6 col-md-4 mt-3">
                                <div className="filterBox">
                                  <h6>{t("bedrooms")}</h6>
                                  <RangeSlider
                                    onInput={handleRangeChange('bedrooms')}
                                    value={filterData.bedrooms}
                                    min={0}
                                    max={100}
                                    step={1}
                                  />
                                  <div className="d-flex pt-2 pb-2 priceRange">
                                    <span>
                                      <strong className="text-success">{filterData.bedrooms[0]}</strong>
                                    </span>
                                    <div className="ml-auto">
                                      - <strong className="text-success">{filterData.bedrooms[1]}</strong>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 mt-3">
                                <div className="filterBox">
                                  <h6>{t("the_bathroom")}</h6>
                                  <RangeSlider
                                    onInput={handleRangeChange('bathrooms')}
                                    value={filterData.bathrooms}
                                    min={0}
                                    max={100}
                                    step={1}
                                  />
                                  <div className="d-flex pt-2 pb-2 priceRange">
                                    <span>
                                      <strong className="text-success">{filterData.bathrooms[0]}</strong>
                                    </span>
                                    <div className="ml-auto">
                                      - <strong className="text-success">{filterData.bathrooms[1]}</strong>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 mt-3">
                              <div className="filterBox">
                                <h6>{t("area")}</h6>
                                <RangeSlider
                                  onInput={handleRangeChange('area')}
                                  value={filterData.area}
                                  min={0}
                                  max={1000}
                                  step={5}
                                />
                                <div className="d-flex pt-2 pb-2 priceRange">
                                  <span>
                                    <strong className="text-success">{filterData.area[0]}</strong>
                                  </span>
                                  <div className="ml-auto">
                                    - <strong className="text-success">{filterData.area[1]}</strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    
                    
                    </div>
                  )}

                </div>
                 {/* //Sort By */}
                <div className="d-flex align-items-center justify-content-between mt-5">
                  <p className="mb-0">{t("result")}</p>

                  <div className="d-flex align-items-center">
                    <span className="me-2">{t("sort")}:</span>

                    <select name="sort_by" onChange={handleChange} className="form-select w-75" value={filterData.sort_by || ""}>
                        <option value="">Sort By</option>
                        <option value="title-asc"> {t("a_z")}</option>
                        <option value="title-desc">{t("z_a")}</option>
                        <option value="created_at-asc">{t("oldest")}</option>
                        <option value="views-desc">{t("most")}</option>
                        <option value="price-asc">{t("l_h")}</option>
                        <option value="price-desc">{t("h_l")}</option>
                      </select>

                  </div>
                </div>

                {/* //Properties Card */}
                <div className="row">
                  {properties.map((property) => (
                    <div className="col-md-6" key={property.id}>
                       <PropertyCard property={property} />
                    </div>
                  ))}

                    
                </div>

                
              </div>
                  {/* //Map Component */}
                <div className="col-md-5 col-sm-12 px-0 order-1">
                  <h4 className=" p-3">{t('search_map_location')}.</h4>
                  <div style={{ width: "100%", maxWidth:'100%', height: "600px", overflow: "hidden" }} className={` ${isSticky ? 'sticky-map' : ''}`}>
                    <MapWithMarkers properties={properties}  currency={t('currency')} />
                  </div>
                </div>

            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default FilteredSideBar;
