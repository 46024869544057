// UserDashboard.js
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Card, Table } from "react-bootstrap";
import ApiService from '../../services/ApiService';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const DashboardPage = () => {
  const user= JSON.parse(localStorage.getItem('user'));
  const { t } = useTranslation();
  
  const [tab, setTab] = useState("email");
  const [first_name, setFirstName] = useState(user.first_name);
  const [last_name, setLastName] = useState(user.last_name);
  const [email, setEmail] = useState(user.email);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoader(true);
    try {
        const formData = {
            "first_name": first_name,
            "last_name": last_name,
            "email": email,
            "old_password": oldPassword,
            "new_password": newPassword,
            "confirm_password": confirmPassword,
          };

        const response = await ApiService.request({
            method: "POST",
            url: "updateAccount", 
            data:formData
        });
        const data = response.data;
        if (data.status) {
            user.email=email;
            user.first_name=first_name;
            user.last_name=last_name;
            localStorage.setItem('user', JSON.stringify(user));
            toast.success(data.message);
        } else {
            toast.error(data.message);
        }
        setIsLoader(false);
    } catch (error) {
        console.log(error);

        setIsLoader(false);
    }
   
};

  return (
    <div>
     
          <div className="dashboard rounded d-flex p-3">
                <div className="card">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                            className={`nav-link border fs-lg-5 ${tab === "email" ? "active" : ""}`}
                            onClick={() => setTab("email")}
                            id="nav-profile-tab"
                            type="button"
                        >
                            <i className="bi bi-envelope"></i> Change Email
                        </button>
                        <button
                            className={`nav-link border fs-lg-5 ${tab === "password" ? "active" : ""}`}
                            onClick={() => setTab("password")}
                            id="nav-contact-tab"
                            type="button"
                        >
                            <i className="bi bi-key"></i> Change Password
                        </button>
                        </div>
                    </nav>
                    <div className="card-body pt-0">
                        <div className="mb-8 text-center"></div>
                        <div className="row g-9 mb-8">
                        <div className="col-md-12">
                            <div className="tab-content mt-5" id="nav-tabContent">
                            {tab === "email" && (
                                <div className="tab-pane fade show active">
                                <form className="form" onSubmit={handleSubmit}>
                                    <div className="row">
                                      <div className="col-md-6 mb-3">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                              <span className="required">First Name</span>
                                          </label>
                                          <input
                                              type="text"
                                              value={first_name}
                                              onChange={(e) => setFirstName(e.target.value)}
                                              className="form-control form-control-solid"
                                              placeholder="Enter First Name"
                                          />
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                              <span className="required">Last Name</span>
                                          </label>
                                          <input
                                              type="text"
                                              value={last_name}
                                              onChange={(e) => setLastName(e.target.value)}
                                              className="form-control form-control-solid"
                                              placeholder="Enter Last Name"
                                          />
                                      </div>
                                      <div className="col-md-6">
                                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                              <span className="required">Email</span>
                                          </label>
                                          <input
                                              type="email"
                                              value={email}
                                              onChange={(e) => setEmail(e.target.value)}
                                              className="form-control form-control-solid"
                                              placeholder="Enter Email"
                                          />
                                      </div>
                                       
                                    </div>
                                   
                                    <button
                                    type="submit"
                                    className="btn btn-base my-5"
                                    disabled={isLoader}
                                    >
                                    <span className="indicator-label">
                                       {t('Update Email')} {isLoader && <span class="spinner-grow spinner-grow-sm"></span>}
                                    </span>
                                    </button>
                                </form>
                                </div>
                            )}
                            {tab === "password" && (
                                <div className="tab-pane fade show active">
                                <form className="form" onSubmit={handleSubmit}>
                                    <div className="row">
                                    <div className="col-md-12">
                                        <label className="required fs-6 fw-semibold mb-2">
                                        Current Password
                                        </label>
                                        <input
                                        type="password"
                                        className="form-control form-control-solid"
                                        placeholder="Enter Your Old Password"
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        />
                                        <br />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="required fs-6 fw-semibold mb-2">
                                        New Password
                                        </label>
                                        <input
                                        type="password"
                                        className="form-control form-control-solid"
                                        placeholder="Enter New Password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="required fs-6 fw-semibold mb-2">
                                        Confirm Password
                                        </label>
                                        <input
                                        type="password"
                                        className="form-control form-control-solid"
                                        placeholder="Enter Confirm Password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                    </div>
                                    </div>
                                    <button
                                    type="submit"
                                    className="btn btn-base my-5"
                                    disabled={isLoader}
                                    >
                                    <span className="indicator-label">
                                      {t('Update Password')} {isLoader && <span class="spinner-grow spinner-grow-sm"></span>}
                                    </span>
                                    </button>
                                </form>
                                </div>
                            )}
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
          
          </div>
        
              
    </div>
  );
};

export default DashboardPage;
