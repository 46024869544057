// components/LanguageToggle.js
import React  from 'react';
import { useTranslation } from 'react-i18next';
import ar from '../assets/img/ar.png'; 
import en from '../assets/img/en.png'; 


const LanguageToggle = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('lang', lng); // Store the selected language in localStorage
        document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
        window.location.reload();
    };



    return (
        <div>
            {i18n.language === 'ar' ? (
                <span className='link p-0' style={{cursor:'pointer'}} onClick={() => changeLanguage('en')}>
                <img src={en} alt="lang" width={'20px'} />  EN 
                </span>
            ) : (
                <span className='link p-0' style={{cursor:'pointer'}} onClick={() => changeLanguage('ar')}>
                    <img src={ar} alt="lang" width={'20px'} /> AR
                </span>
            )}
        </div>
    );
};

export default LanguageToggle;
