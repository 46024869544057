import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
    const navigate = useNavigate()
    return (
      <span className="cursor-pointer pe-2" onClick={() => navigate(-1)}>
        <MdOutlineKeyboardBackspace />
      </span>
    );
}

export default BackButton