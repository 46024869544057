import React, {  useEffect } from "react";

import Topbar from "../components/Top Bar/Topbar";
import Footer from "../components/Footer/Footer";
import CustomNavbar from "../components/navBar/NavBar";
import { useTranslation } from "react-i18next";
import { NavLink  } from 'react-router-dom';


const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const app_lang=localStorage.getItem('lang') || 'en';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Topbar />
      <CustomNavbar />
      
      <div className="estate-header">
        <div className="header-txt text-white">
          <p>
              <NavLink to="/" className="nav-link d-inline">  {t('home')} </NavLink>  
              <i className={`bi ${app_lang === 'en' ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i> {t('privacy_policy')}
          </p>
        
        </div>
      </div>

      <div className="container mb-5">
        <div className="bg-white mt-5 p-5">
          <h4 className="fw-semibold">{t('privacy_policy')}</h4>

          {app_lang === 'en'? (
            <div className="main-en">
              <h4 className="fw-normal">About the Contents of This Page</h4>
              <p>
                  The content of this website’s pages is for your general information and use only, and it is subject to change without notice. Using this website signifies acceptance of the terms and conditions of use and privacy policy contained within these pages.
              </p>

              <h4 className="fw-normal">Personal Data on the Internet</h4>
              <p>
                  Our website does not collect any data about visitors unless the visitor sends it to us, whether for inquiries or subscriptions. All data consists of numbers, dates, and program addresses only, and we do not retain the names of clients or visitors within our website at any time.
              </p>

              <h4 className="fw-normal">Financial Data on the Internet</h4>
              <p>
                  Our website does not store financial data. All transactions are handled exclusively by entities approved by the Saudi Central Bank. Electronic payments are processed directly on sites approved by official government bodies.
              </p>

              <h4 className="fw-normal">Sending Comments and Contact Forms</h4>
              <p>
                  Posting comments on websites or sending messages in any form means that the displayed data and user contact information, such as IP address and browser type, may be collected to prevent misuse.
              </p>

              <h4 className="fw-normal">Uploading Image or Video Files</h4>
              <p>
                  Avoid uploading images that contain location data (EXIF GPS), as other visitors may download the images and extract your data. You can choose to disable location data in your phone's camera settings.
              </p>

              <h4 className="fw-normal">Cookies</h4>
              <p>
                  Pages and programs require setting data to function properly; hence, they save temporary files on the user’s device. These files are generally used to keep track of the pages you have visited on the website.
              </p>

              <h4 className="fw-normal">Sites Sharing Content with Us</h4>
              <p>
                  All content on our website is hosted on our servers. However, some software may send data to us to operate or update specific programs or content, such as software updates from original company sites or videos hosted by YouTube, as well as tweets provided by Twitter, among others.
              </p>

              <h4 className="fw-normal">Who We Share Data With</h4>
              <p>
                  Generally, we do not share data with anyone. If a client registers with other service sites from within their programs on our website, we do not share our data with any entity unless required by government authorities. We comply with applicable regulations in Saudi Arabia.
              </p>

              <h4 className="fw-normal">How Long Data Is Retained</h4>
              <p>
                  Some data is linked to official business transaction systems, such as invoice data, which is preserved by companies accredited by the Saudi Central Bank as per regulations. General internet data, such as comments and messages, is retained as long as the associated pages exist and can be removed if it is no longer relevant or useful to the original content.
              </p>

              <h4 className="fw-normal">Your Rights Over Your Data</h4>
              <p>
                  If you have old data on this site, you may request a copy of it, and you can request to delete publicly displayed data or even deactivate or delete your account before the end of your subscription if necessary. This does not necessarily delete data required for administrative, legal, or security purposes.
              </p>

              <h4 className="fw-normal">Where Data Is Sent</h4>
              <p>
                  All site data is sent to and from hosting servers inside and outside Saudi Arabia, with full copies saved monthly on backup storage devices for site hosting or design services within and outside Saudi Arabia. Note that when sending and receiving data or emails, it passes through multiple points on the global internet, and if your data is not encrypted, other entities on the internet may be able to view it.
              </p>

              <p>
                  If you have any inquiries about the contents of this page, please contact us through social media, the contact form page, mobile, or WhatsApp at +966533492225.
              </p>


            </div>
          ):(
            <div className="main-ar">
              <h4 className="fw-normal">حول محتويات هذه الصفحة</h4>
              <p>
                  محتوى صفحات هذا الموقع هو للمعلومات العامة والاستخدام فقط، وهو قابل للتغيير دون إشعار مسبق. استخدامك لهذا الموقع يعني قبولك بالشروط والأحكام وسياسة الخصوصية الواردة في هذه الصفحات.
              </p>

              <h4 className="fw-normal">البيانات الشخصية على الإنترنت</h4>
              <p>
                  لا يقوم موقعنا بجمع أي بيانات عن الزوار إلا إذا قام الزائر بإرسالها إلينا، سواء للاستفسارات أو الاشتراكات. تتكون جميع البيانات من أرقام، تواريخ، وعناوين برامج فقط، ونحن لا نحتفظ بأسماء العملاء أو الزوار على موقعنا في أي وقت.
              </p>

              <h4 className="fw-normal">البيانات المالية على الإنترنت</h4>
              <p>
                  لا يقوم موقعنا بتخزين البيانات المالية. تتم معالجة جميع المعاملات من قبل الكيانات المعتمدة من قبل البنك المركزي السعودي. تتم معالجة المدفوعات الإلكترونية مباشرة على المواقع المعتمدة من قبل الهيئات الحكومية الرسمية.
              </p>

              <h4 className="fw-normal">إرسال التعليقات ونماذج الاتصال</h4>
              <p>
                  نشر التعليقات على المواقع أو إرسال الرسائل بأي شكل يعني أن البيانات المعروضة ومعلومات الاتصال بالمستخدم، مثل عنوان IP ونوع المتصفح، قد يتم جمعها لمنع سوء الاستخدام.
              </p>

              <h4 className="fw-normal">رفع الصور أو مقاطع الفيديو</h4>
              <p>
                  تجنب رفع الصور التي تحتوي على بيانات الموقع (EXIF GPS)، حيث قد يقوم الزوار الآخرون بتنزيل الصور واستخراج بياناتك. يمكنك اختيار تعطيل بيانات الموقع في إعدادات كاميرا هاتفك.
              </p>

              <h4 className="fw-normal">ملفات تعريف الارتباط (Cookies)</h4>
              <p>
                  تتطلب الصفحات والبرامج إعداد بيانات لتعمل بشكل صحيح؛ لذلك، فهي تحفظ ملفات مؤقتة على جهاز المستخدم. تستخدم هذه الملفات عمومًا لمتابعة الصفحات التي قمت بزيارتها على الموقع.
              </p>

              <h4 className="fw-normal">المواقع التي تشارك المحتوى معنا</h4>
              <p>
                  جميع المحتويات على موقعنا مستضافة على خوادمنا. ومع ذلك، قد تقوم بعض البرمجيات بإرسال بيانات إلينا لتشغيل أو تحديث برامج أو محتويات معينة، مثل تحديثات البرمجيات من مواقع الشركات الأصلية أو مقاطع الفيديو المستضافة على يوتيوب، وكذلك التغريدات المقدمة من تويتر، من بين آخرين.
              </p>

              <h4 className="fw-normal">من نشارك البيانات معه</h4>
              <p>
                  بشكل عام، لا نشارك البيانات مع أي جهة. إذا قام العميل بالتسجيل في مواقع خدمات أخرى من خلال برامجها على موقعنا، فإننا لا نشارك بياناتنا مع أي جهة ما لم يكن ذلك مطلوبًا من قبل السلطات الحكومية. نحن نلتزم باللوائح المعمول بها في المملكة العربية السعودية.
              </p>

              <h4 className="fw-normal">مدة احتفاظ البيانات</h4>
              <p>
                  بعض البيانات مرتبطة بأنظمة المعاملات التجارية الرسمية، مثل بيانات الفواتير، والتي يتم حفظها من قبل الشركات المعتمدة من قبل البنك المركزي السعودي وفقًا للوائح. يتم الاحتفاظ بالبيانات العامة على الإنترنت، مثل التعليقات والرسائل، طالما كانت الصفحات المرتبطة بها موجودة ويمكن إزالتها إذا لم تعد ذات صلة أو فائدة للمحتوى الأصلي.
              </p>

              <h4 className="fw-normal">حقوقك على بياناتك</h4>
              <p>
                  إذا كانت لديك بيانات قديمة على هذا الموقع، يمكنك طلب نسخة منها، ويمكنك طلب حذف البيانات المعروضة علنًا أو حتى تعطيل أو حذف حسابك قبل نهاية اشتراكك إذا لزم الأمر. هذا لا يعني بالضرورة حذف البيانات المطلوبة للأغراض الإدارية أو القانونية أو الأمنية.
              </p>

              <h4 className="fw-normal">إلى أين تُرسل البيانات</h4>
              <p>
                  يتم إرسال جميع بيانات الموقع من وإلى خوادم الاستضافة داخل وخارج المملكة العربية السعودية، ويتم حفظ نسخ كاملة شهريًا على أجهزة التخزين الاحتياطية لخدمات استضافة أو تصميم المواقع داخل وخارج المملكة العربية السعودية. لاحظ أنه عند إرسال واستقبال البيانات أو الرسائل الإلكترونية، فإنها تمر عبر عدة نقاط على الإنترنت العالمي، وإذا كانت بياناتك غير مشفرة، قد تتمكن كيانات أخرى على الإنترنت من رؤيتها.
              </p>

              <p>
                  إذا كان لديك أي استفسارات حول محتويات هذه الصفحة، يرجى الاتصال بنا عبر وسائل التواصل الاجتماعي أو صفحة نموذج الاتصال أو الهاتف المحمول أو عبر واتساب على الرقم +966533492225.
              </p>
            </div>

          )}

        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;