import { useState } from "react";
import { Carousel } from "react-bootstrap";

const Carousel2 = ({ items, imageStyles = {}, disableThumbnails = false }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div>
      <Carousel
        activeIndex={activeIndex}
        onSelect={handleSelect}
        interval={null}  // Ensure this is null to prevent auto sliding
        controls={true}  // Ensure controls are enabled for manual navigation
      >
        {items.map((item, index) => (
          <Carousel.Item
            key={index}
            className="bg-black d-flex justify-content-center"
          >
            {item.type === "image" ? (
              <img
                className="d-block"
                src={item.file}
                alt="Carousel Item"
                style={{
                  height: "20rem",
                  objectFit: "contain",
                  ...imageStyles,
                }}
              />
            ) : (
              <video
                src={item.file}
                controls
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Thumbnails */}
      {!disableThumbnails && (
        <div className="mt-3 d-flex gap-4 flex-wrap">
          {items?.map((item, index) => (
            <img
              key={index}
              className={`d-block rounded-3 cursor-pointer ${
                activeIndex === index ? "border border-2 border-primary" : ""
              }`}
              src={item.file}
              alt="Thumbnail"
              style={{ height: "4rem", width: "6rem" }}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Carousel2;
