import React, { useState, useEffect } from "react";
import CustomNavbar from "../components/navBar/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Topbar from "../components/Top Bar/Topbar";
import PageLoader from "../components/PageLoader";
import ApiService from "../services/ApiService";
import { toast } from "react-toastify";

import { Row, Col } from "react-bootstrap";
import CountdownTimer2 from "../components/CountdownTimer/CountdownTimer2";
import { MdOutlineInfo } from "react-icons/md";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaRegFilePdf } from "react-icons/fa6";
import BackButton from "../components/BackButton";
import { GrGallery } from "react-icons/gr";
import Carousel2 from "../components/Carousel/Carousel2";
import {
  calculatePercentage,
  formatNumber,
  getDateMs,
  isAuctionLive,
  isPastDate,
  setRedirectionLink,
} from "../utils/helper";
import PaymentModal from "../components/PaymentModal";
import { TiTick } from "react-icons/ti";
import Footer from "../components/Footer/Footer";

const AssetDetails = () => {
  const { t } = useTranslation();
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const navigate = useNavigate();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [assetData, setAssetData] = useState({});
  const ifPaymentDone = assetData?.payment?.pay_status === "Paid";

  const [isPageLoader, setPageLoader] = useState(false);
  const isAuctionEnded = isPastDate(assetData.auctions?.end_date);
  const { id } = useParams();

  const [bidValue, setBidValue] = useState(0);
  const [biddingLoader, setBiddingLoader] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAssetDetails(true);
  }, []);

  const getAssetDetails = async (loader = false) => {
    loader && setPageLoader(true);
    try {
      const response = await ApiService.request({
        method: "GET",
        url: `get-auction-asset-details/${id}`, // Replace with your API endpoint
      });
      const data = response.data;
      if (data.status) {
        setAssetData(data.data);
        setBidValue(
          data.data?.payment?.pay_status === "Paid"
            ? +data.data.highestBid
            : +data.data.auctions.deposit_starts_from
        );
      } else {
        toast.error(data.message);
      }
    } catch (error) {
    } finally {
      setPageLoader(false);
    }
  };

  const depositHandler = () => {
    if (user) {
      if (ifPaymentDone) bidNow();
      else {
        if (sessionStorage.getItem("captcha_verification")) {
          initiatePayment();
        } else {
          setRedirectionLink(window.location.href);
          navigate("/nafath-auth");
        }
      }
    } else {
      setRedirectionLink(window.location.href);
      navigate("/login");
    }
  };

  const getButtonText = () => {
    if (user) {
      if (ifPaymentDone) {
        return t("bid_now");
      } else return t("deposit");
    } else return t("registration");
  };

  const handleValueChange = (action) => {
    if (action === "increment") {
      setBidValue((ps) => ps + +assetData.auctions?.increment_value);
    }
    if (action === "decrement") {
      setBidValue((ps) => ps - +assetData.auctions?.increment_value);
    }
  };

  const bidNow = async () => {
    if (bidValue <= 0) {
      toast.error("Invalid bidding value");
      return;
    }

    setBiddingLoader(false);
    try {
      const response = await ApiService.request({
        method: "POST",
        url: `bidAuction`, // Replace with your API endpoint
        data: { auction_id: id, amount: bidValue },
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        getAssetDetails();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBiddingLoader(false);
    }
  };

  const initiatePayment = async () => {
    setBiddingLoader(true);
    try {
      const response = await ApiService.request({
        method: "POST",
        url: `initiatePayment`, // Replace with your API endpoint
        data: { amount: +assetData?.auctions?.deposit_starts_from },
      });
      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        setPaymentMethods(data.data.paymentMethods);
        setShow(true);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBiddingLoader(false);
    }
  };

  const exceutePayment = async (apiData, setLoading) => {
    try {
      const apiPayload = {
        auction_id: id,
        amount: bidValue,
        paymentMethodId: apiData.paymentMethodId,
        customerName: apiData.customerName,
        customerEmail: apiData.customerEmail,
      };

      const response = await ApiService.request({
        method: "POST",
        url: `executePayment`, // Replace with your API endpoint
        data: apiPayload,
      });

      const data = response.data;

      if (data.status) {
        toast.success(data.message);
        window.open(data.data.payment_url, "_self");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const commissionValue = calculatePercentage(
    +assetData.auctions?.commission,
    bidValue
  );

  console.log({ commissionValue });

  return (
    <div>
      <PaymentModal
        show={show}
        setShow={setShow}
        exceutePayment={exceutePayment}
        paymentMethods={paymentMethods}
      />
      {isPageLoader ? (
        <PageLoader />
      ) : (
        <div>
          <Topbar />
          <CustomNavbar />

          <div className="bg-white py-4 px-5">
            <h2>
              <BackButton /> {assetData?.auctions?.title}
            </h2>
            <Row className="w-100">
              <Col md={7}>
                <Carousel2
                  items={assetData?.auctions?.media_files || []}
                  disableThumbnails
                />

                <div className="d-flex justify-content-between align-items-center mt-3">
                  {/* <div className="d-flex gap-4">
                  <img
                    src={"https://picsum.photos/40/20"}
                    alt="Product"
                    className="assetData-img"
                  />
                  <div class="vr"></div>
                  <img
                    src={"https://picsum.photos/40/20"}
                    alt="Product"
                    className="assetData-img"
                  />
                </div> */}
                  <div>
                    <button
                      className="btn btn-base py-2 px-4"
                      onClick={() =>
                        window.open(assetData?.auctions?.brochure, "_blank")
                      }
                    >
                      {t("brochure")} <FaRegFilePdf />
                    </button>
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-3">
                  <button
                    className="btn btn-base py-2 px-4"
                    // onClick={() => setShow(true)}
                  >
                    {t("gallery")} <GrGallery />
                  </button>
                  <button className="btn btn-light py-2 px-4">
                    {t("share_it")} <IoShareSocialOutline className="" />
                  </button>
                </div>

                {!!assetData?.bids?.length && (
                  <table className="table mt-4 table-bordered">
                    <thead className="">
                      <tr className="">
                        <th scope="col" className="bg-tertiary">
                          {t("bidder_name")}
                        </th>
                        <th scope="col" className="bg-tertiary">
                          {t("bid_price")}
                        </th>
                        <th scope="col" className="bg-tertiary">
                          {t("when")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {assetData?.bids?.map((item) => (
                        <tr>
                          <td>
                            {item.user.first_name + " " + item.user.last_name}
                          </td>
                          <td>
                            {t("sar")} {formatNumber(item.amount)}
                          </td>
                          <td>
                            {moment(item.created_at).startOf("day").fromNow()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                <div>
                  <div className="mt-5">
                    <h3>{t("street_details")}</h3>
                    <div className="row">
                      <div className="col-3">
                        <div className="d-flex justify-content-between gap-4">
                          <b>{t("name")}</b>
                          <span>{assetData?.auctions?.street_name}</span>
                        </div>
                        <div className="d-flex justify-content-between gap-4">
                          <b>{t("street_width")}</b>
                          <span>{assetData?.auctions?.street_width}</span>
                        </div>
                      </div>
                      <div className="col-9"></div>
                    </div>
                    <hr />
                  </div>

                  <div className="mt-5">
                    <h3>{t("additional_info")}</h3>
                    <div className="row">
                      <div className="col-3">
                        <div className="d-flex justify-content-between gap-4">
                          <b>{t("asset_type")}</b>
                          <span>{assetData?.auctions?.asset_type}</span>
                        </div>
                        <div className="d-flex justify-content-between gap-4">
                          <b>{t("bathroom")}</b>
                          <span>{assetData?.auctions?.bathroom}</span>
                        </div>
                        <div className="d-flex justify-content-between gap-4">
                          <b>{t("bedrooms")}</b>
                          <span>{assetData?.auctions?.bedroom}</span>
                        </div>
                        <div className="d-flex justify-content-between gap-4">
                          <b>{t("electricity")}</b>
                          <span>
                            {assetData?.auctions?.electricity
                              ? t("yes")
                              : t("no")}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between gap-4">
                          <b>{t("water")}</b>
                          <span>
                            {assetData?.auctions?.water ? t("yes") : t("no")}
                          </span>
                        </div>
                      </div>
                      <div className="col-9"></div>
                    </div>
                    <hr />
                  </div>

                  <div className="mt-5">
                    <h3>{t("length_border")}</h3>
                    <div className="row">
                      <div className="col-5">
                        <div className="d-flex justify-content-between gap-4">
                          <b>{t("north")}</b>{" "}
                          <span>{assetData?.auctions?.north}</span>
                        </div>
                        <div className="d-flex justify-content-between gap-4">
                          <b>{t("south")}</b>{" "}
                          <span>{assetData?.auctions?.south}</span>
                        </div>
                        <div className="d-flex justify-content-between gap-4">
                          <b>{t("east")}</b>
                          <span>{assetData?.auctions?.east}</span>
                        </div>
                        <div className="d-flex justify-content-between gap-4">
                          <b>{t("west")}</b>
                          <span>{assetData?.auctions?.west}</span>
                        </div>
                      </div>
                      <div className="col-7"></div>
                    </div>
                    <hr />
                  </div>
                </div>

                {/* map */}
                <div
                  style={{ width: "100%", height: "500px", overflow: "hidden" }}
                >
                  <h3 className="my-3">{t("location_details")}</h3>
                  <iframe
                    src={`https://www.google.com/maps?q=${assetData?.auctions?.lat},${assetData?.auctions?.lng}&hl=es;z=14&output=embed`}
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title={"asset"}
                  ></iframe>
                </div>
              </Col>
              <Col md={5} className="relative">
                <div className="position-fixed w-inherit d-flex flex-column align-items-center">
                  <div className="w-100">
                    {/* if auction is closed */}
                    {isAuctionEnded && (
                      <div className="text-center fw-bold">
                        Auction closed on{" "}
                        {moment(assetData?.auctions?.end_date).format(
                          "MMMM Do YYYY"
                        )}
                      </div>
                    )}
                    {!isAuctionEnded && assetData?.auctions?.start_date && (
                      <CountdownTimer2
                        className=""
                        targetTimestamp={getDateMs(
                          assetData?.auctions?.start_date
                        )}
                        startDate={assetData?.auctions?.start_date}
                        endDate={assetData?.auctions?.end_date}
                        theme="alpha"
                      />
                    )}
                  </div>

                  {!isAuctionEnded && (
                    <div className="px-3 w-100">
                      <div className="mt-3 d-flex justify-content-between bg-light-gamma rounded-1 p-3">
                        <div className="d-flex flex-column">
                          <span>{t("opening_price")}</span>
                          <span>
                            <b>
                              {formatNumber(assetData?.auctions?.open_price)}
                            </b>{" "}
                            {t("sar")}
                          </span>
                        </div>
                        <div className="d-flex flex-column">
                          <span>{t("deposit_amount")}</span>
                          {assetData?.auctions?.deposit_starts_from && (
                            <span>
                              <b>
                                {formatNumber(
                                  assetData?.auctions?.deposit_starts_from
                                )}
                              </b>{" "}
                              {t("sar")}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {!isAuctionEnded && (
                    <div
                      className={`w-100 px-3 mt-3 ${
                        isAuctionLive(
                          assetData?.auctions?.start_date,
                          assetData?.auctions?.end_date
                        )
                          ? ""
                          : "disabled"
                      }`}
                    >
                      {assetData?.auctions?.payment !== "Paid" && (
                        <div className="d-flex justify-content-between w-100">
                          <div>
                            <b>{t("make_the_initial_offer")}</b>{" "}
                            <MdOutlineInfo size={20} />
                          </div>
                          <span>
                            {t("increase")}{" "}
                            <b>{assetData.auctions?.increment_value}</b>{" "}
                            {t("sar")}
                          </span>
                        </div>
                      )}
                      {assetData?.payment?.pay_status === "Paid" &&
                        assetData?.alreadyBid && (
                          <div className="mx-1 mt-3 rounded-3 w-100 row w-100 border border-light-alpha">
                            <span
                              className={`col-2 text-center p-3 light-alpha cursor-pointer ${
                                assetData.highestBid >= bidValue
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() => handleValueChange("decrement")}
                            >
                              <FaMinus className="light-alpha" size={22} />
                            </span>
                            <span className="col-8 border-start border-end border-light-alpha text-center p-3">
                              <b>{formatNumber(bidValue)}</b>
                            </span>
                            <span
                              className="col-2 text-center p-3 light-alpha cursor-pointer"
                              onClick={() => handleValueChange("increment")}
                            >
                              <FaPlus className="light-alpha" size={22} />
                            </span>
                          </div>
                        )}
                      <button
                        className="w-100 mt-3 btn btn-base p-3 text-white"
                        onClick={depositHandler}
                        disabled={biddingLoader || !assetData.alreadyBid}
                      >
                        {getButtonText()}
                        {biddingLoader && (
                          <span
                            className="spinner-border spinner-border-sm ms-1"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    </div>
                  )}

                  {assetData?.payment?.pay_status === "Paid" && (
                    <div className="px-3 w-100">
                      <div className="text-center my-2 bg-tertiary p-2">
                        <b>{t("registered")}</b>{" "}
                        <TiTick color="green" size={24} />
                      </div>
                    </div>
                  )}

                  <div className="w-100 px-4 fs-5 mt-4">
                    <div
                      className="d-flex justify-content-between pb-2"
                      style={{
                        borderBottom: "1px dashed var(--bs-quaternary)",
                      }}
                    >
                      <span>{t("price_per_square_meter")}</span>
                      <span>
                        <b>{assetData.auctions?.price_per_sqm}</b>
                      </span>
                    </div>

                    <div
                      className="mt-2 d-flex justify-content-between pb-2"
                      style={{
                        borderBottom: "1px dashed var(--bs-quaternary)",
                      }}
                    >
                      <span>
                        {t("commission")} {assetData.auctions?.commission}%
                      </span>
                      <span>
                        <b>{formatNumber(commissionValue)}</b> {t("sar")}
                      </span>
                    </div>

                    <div
                      className="mt-2 d-flex justify-content-between pb-2"
                      style={{
                        borderBottom: "1px dashed var(--bs-quaternary)",
                      }}
                    >
                      <span className="fs-6 fw-bold">{t("total")}</span>
                      <span>
                        <b>{formatNumber(commissionValue + bidValue)}</b>{" "}
                        {t("sar")}
                      </span>
                    </div>

                    {/* <div className="mt-2 d-flex justify-content-between pb-2">
                    <span>
                      <b>0 {t("bidders")}</b>
                    </span>
                    <span className="fs-6 fw-bold"></span>
                  </div>

                  <div className="">
                    <h5>Highest Bidders</h5>
                    <div className="bg-light-gamma p-3"></div>
                  </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default AssetDetails;
