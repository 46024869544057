import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../utils/helper";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const PaymentModal = ({ show, setShow, paymentMethods, exceutePayment }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const initialState = {
    customerName: "",
    customerEmail: "",
    paymentMethodId: null,
  };

  const [userData, setUserData] = useState({
    customerName: "",
    customerEmail: "",
    paymentMethodId: null,
  });

  useEffect(() => {
    if (!show) {
      setUserData({ ...initialState });
    }
  }, [show]);

  const handleChange = (e, name, value) => {
    setUserData((ps) => ({
      ...ps,
      [name || e.target.name]: value || e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userData.paymentMethodId) {
      toast.error("Please select Payment method");
      return;
    }
    setLoading(true);
    exceutePayment(userData, setLoading);
  };

  return (
    <Modal show={show} fullscreen onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <b className="fs-4">Choose Payment</b>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Row className="mb-4">
            <Col sm={12} md={6} lg={4}>
              <input
                className="form-control mb-3"
                aria-describedby="emailHelp"
                placeholder={t("customer_name")}
                required
                name="customerName"
                onChange={handleChange}
              />
              <input
                type="email"
                className="form-control"
                aria-describedby="emailHelp"
                placeholder={t("customer_email")}
                required
                name="customerEmail"
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="w-100 mb-3">
            {paymentMethods?.map((item) => (
              <Col key={item.id} sm={12} md={6} lg={4} xl={3}>
                <PaymentMethod
                  selectedId={userData.paymentMethodId}
                  data={item}
                  onClick={() =>
                    handleChange(
                      undefined,
                      "paymentMethodId",
                      item.PaymentMethodId
                    )
                  }
                />
              </Col>
            ))}
          </Row>

          <button
            className="btn btn-base py-2 px-4"
            // onClick={() => window.open(assetData?.auctions?.brochure, "_blank")}
            disabled={loading}
          >
            {loading ? "Submitting..." : t("submit")}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;

const PaymentMethod = ({ data, onClick, selectedId }) => {
  const { t } = useTranslation();
  const app_lang = localStorage.getItem("lang") || "en";

  return (
    <div
      className={`mb-5 cursor-pointer p-2 card-hover ${
        selectedId === data.PaymentMethodId ? "selected-card" : ""
      }`}
      onClick={onClick}
    >
      <img
        className="mb-2"
        src={data.ImageUrl}
        alt={data.PaymentMethodEn}
        style={{ height: "8rem", objectFit: "cover" }}
      />
      <Row className="mb-1">
        <Col>
          <b>{t("payment_method")}</b>
        </Col>
        <Col>
          {app_lang === "en" ? data.PaymentMethodEn : data.PaymentMethodAr}
        </Col>
      </Row>
      <Row className="mb-1">
        <Col>
          <b>{t("service_charge")}</b>
        </Col>
        <Col>{data.ServiceCharge}</Col>
      </Row>
      <Row className="mb-1">
        <Col>
          <b>{t("total")}</b>
        </Col>
        <Col>
          {formatNumber(data.TotalAmount)} {t("sar")}
        </Col>
      </Row>
    </div>
  );
};
